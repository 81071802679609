import { useAppTFunction } from '@dap-common/i18n';
import { Buttons } from '@dap-common/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ContainedButton, Dialog, OutlinedButton, TextInputField } from '@shared/components';
import { useMountEffect } from '@shared/hooks';
import { useMemo } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

interface Props {
  handleClose: () => void;
  brandName: string;
  handleDelete: () => void;
}

export default function DeleteBrandDialog({ handleClose, brandName, handleDelete }: Props) {
  const t = useAppTFunction();

  const schema = useMemo(
    () =>
      yup.object({
        name: yup.string().required().default('').oneOf([brandName]),
      }),
    [brandName]
  );

  const form = useForm({
    defaultValues: schema.getDefault(),
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const { handleSubmit, control, trigger } = form;
  const nameController = useController({ name: 'name', control });

  // Trigger validation immediately upon mounting
  useMountEffect(() => {
    trigger('name');
  });

  return (
    <Dialog open={true} fullWidth maxWidth="sm" onClose={handleClose}>
      <DialogTitle>{t('brandadmin.delete', { instance: brandName })}</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <Typography mb={2}>
            {t('brandDetailsPage.deleteBrand.confirm.text', {
              brand: brandName,
            })}
          </Typography>
          <Typography>{t('brandDetailsPage.deleteBrand.info')}</Typography>
          <TextInputField
            {...nameController.field}
            required
            label={t('brandadmin.deleteEntity.label')}
            fullWidth
          />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Buttons>
          <OutlinedButton onClick={handleClose}>{t('common.cancel')}</OutlinedButton>
          <ContainedButton
            disabled={!!nameController.fieldState.error}
            onClick={handleSubmit(handleDelete)}
          >
            {t('brandadmin.deleteEntity.confirmButton')}
          </ContainedButton>
        </Buttons>
      </DialogActions>
    </Dialog>
  );
}
