import { LocationEditSchema } from '@dap-admin/schemas';
import { AppBasic } from '@dap-admin/types';
import { useServiceFilters } from '@dap-admin/utils';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexRow, useCheckboxGroup } from '@dap-common/ui';
import { Checkbox, FormControl, FormGroup, List, Stack, Typography } from '@mui/material';
import { InfoTooltip } from '@shared/components';
import { useController, useFormContext } from 'react-hook-form';
import AppOutlinedListItem from '../../List/AppOutlinedListItem';

interface Props {
  availableServices: Array<AppBasic>;
}

export default function EditServices({ availableServices }: Props) {
  const t = useAppTFunction();

  const { control } = useFormContext<LocationEditSchema>();
  const { field: appsControllerField } = useController({
    name: 'services.apps',
    control,
  });
  const { defaultServices, filteredServices } = useServiceFilters(availableServices);

  const { handleChange } = useCheckboxGroup({
    onChange: appsControllerField.onChange,
    value: appsControllerField.value,
  });

  return (
    <FormControl component={'fieldset'} fullWidth>
      <FormGroup>
        <Stack mt={2} mb={4}>
          <Typography variant="body1" fontWeight={700}>
            {t('brandadmin.services.title')}
          </Typography>
          {filteredServices.length > 0 ? (
            <List>
              {filteredServices.map((service) => (
                <AppOutlinedListItem
                  key={service.key}
                  app={service}
                  secondaryAction={
                    <Checkbox
                      edge={'end'}
                      checked={appsControllerField.value.includes(service.key)}
                      value={service.key}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        handleChange(event, checked, availableServices, false)
                      }
                      onBlur={appsControllerField.onBlur}
                    />
                  }
                />
              ))}
            </List>
          ) : (
            <Typography>{t('brandadmin.services.empty')}</Typography>
          )}
        </Stack>

        <Stack>
          <FlexRow alignItems={'center'}>
            <Typography variant="body1" fontWeight={700}>
              {t('location.detail.services.default.title')}
            </Typography>
            <InfoTooltip tooltipText={t('location.detail.services.default.description')} />
          </FlexRow>
          {defaultServices.length > 0 ? (
            <List>
              {defaultServices.map((service) => (
                <AppOutlinedListItem
                  key={service.key}
                  app={service}
                  secondaryAction={
                    <Checkbox
                      edge={'end'}
                      checked={appsControllerField.value.includes(service.key)}
                      value={service.key}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        handleChange(event, checked, availableServices, false)
                      }
                      onBlur={appsControllerField.onBlur}
                    />
                  }
                />
              ))}
            </List>
          ) : (
            <Typography>{t('brandadmin.services.empty')}</Typography>
          )}
        </Stack>
      </FormGroup>
    </FormControl>
  );
}
