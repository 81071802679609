import { ExpandMore, ExpandMoreRounded } from '@mui/icons-material';
import { Theme, alpha } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';
import {
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
  RadioCheckedIcon,
  RadioUncheckedIcon,
} from '@shared/custom-icons';
import { DataGridClass, TabsClass } from './classes';

export const componentsStyles: Theme['components'] = {
  MuiAppBar: {
    defaultProps: {
      elevation: 0,
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      rounded: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius * 3,
      }),
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        fontFamily: 'inherit',
      },
    },
    defaultProps: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        borderWidth: ownerState.size === 'large' ? 2 : 1,
        borderStyle: 'solid',
        borderRadius: theme.shape.borderRadius * 3,
        textTransform: 'none',
        strokeWidth: 2,
        fontWeight: 500,

        '&:hover': {
          borderWidth: ownerState.size === 'large' ? 2 : 1,
          borderStyle: 'solid',
          boxShadow: theme.shadows[1],
          transition: 'none', // Remove for smoother border transition
          borderColor: theme.palette.grey[900],
          color: theme.palette.common.white,
          background: theme.palette.grey[900],
        },
        '&:focus-visible': {
          boxShadow: theme.shadows[1],
        },
        '&:disabled': {
          borderWidth: ownerState.size === 'large' ? 2 : 1,
          borderStyle: 'solid',
          background: theme.palette.grey.A100,
          color: theme.palette.text.disabled,
          borderColor: theme.palette.grey.A100,
        },
      }),
      sizeSmall: ({ theme }) => ({
        ...theme.typography.body2,
        fontWeight: 500,
        padding: '9px 16px',
      }),
      sizeLarge: ({ theme }) => ({
        ...theme.typography.body1,
        fontWeight: 500,
        padding: '10px 38px',
      }),
      containedPrimary: ({ theme }) => ({
        color: theme.palette.common.white,
        background: theme.palette.gradient.primary,
        transition: 'none', // Remove for smoother border transition
        // Compensate with padding for no border color, cannot use gradient border color
        border: 'none',
        '&.MuiButton-sizeSmall': {
          padding: '10px 17px',
          ':hover, :disabled': {
            border: 0,
            padding: '10px 17px',
          },
        },
        '&.MuiButton-sizeLarge': {
          padding: '12px 40px',
          ':hover, :disabled': {
            border: 0,
            padding: '12px 40px',
          },
        },
      }),
      containedSecondary: ({ theme }) => ({
        color: theme.palette.text.primary,
        background: theme.palette.gradient.secondary,
        borderColor: theme.palette.secondary.main,
        transition: 'none', // Remove for smoother border transition
      }),
      containedWarning: ({ theme }) => ({
        color: theme.palette.text.primary,
        background: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
      }),
      outlined: {
        '&.MuiButton-sizeSmall': {
          padding: '9px 16px',
          ':hover, :disabled': {
            padding: '9px 16px',
          },
        },
      },
      outlinedPrimary: ({ theme }) => ({
        color: theme.palette.gradient.primary,
        background: `linear-gradient(${theme.palette.common.white}, ${theme.palette.common.white}) padding-box, ${theme.palette.gradient.primary} border-box`,
        transition: 'none', // Remove for smoother border transition
      }),
      outlinedSecondary: ({ theme }) => ({
        color: theme.palette.text.secondary,
        background: `linear-gradient(${theme.palette.common.white}, ${theme.palette.common.white}) padding-box, ${theme.palette.gradient.secondary} border-box`,
        transition: 'none', // Remove for smoother border transition
      }),
      outlinedWarning: ({ theme }) => ({
        color: theme.palette.text.secondary,
        background: theme.palette.common.white,
        borderColor: theme.palette.warning.main,
      }),
      text: () => ({
        borderColor: 'transparent',
        transition: 'none', // Remove for smoother border transition
      }),
    },
    variants: [
      {
        props: { color: 'grey', variant: 'outlined' },
        style: ({ theme }) => ({
          color: theme.palette.text.secondary,
          background: theme.palette.common.white,
          borderColor: theme.palette.grey.A100,
        }),
      },
      {
        props: { color: 'grey', variant: 'contained' },
        style: ({ theme }) => ({
          color: theme.palette.text.secondary,
          background: theme.palette.common.white,
          borderColor: theme.palette.common.white,
        }),
      },
    ],
  },
  MuiToggleButton: {
    defaultProps: {
      disableFocusRipple: true,
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        borderWidth: ownerState.size === 'large' ? 2 : 1,
        borderStyle: 'solid',
        borderRadius: theme.shape.borderRadius * 3,
        textTransform: 'none',
        background: theme.palette.common.white,
        '&.Mui-selected': {
          borderColor: theme.palette.primary.main,
          background: theme.palette.common.white,
        },
        '&:hover, &.Mui-selected:hover': {
          borderWidth: ownerState.size === 'large' ? 2 : 1,
          borderStyle: 'solid',
          boxShadow: theme.shadows[1],
          transition: 'none', // Remove for smoother border transition
          borderColor: theme.palette.grey[900],
          color: theme.palette.common.white,
          background: theme.palette.grey[900],
        },
        '&:focus-visible': {
          boxShadow: theme.shadows[1],
        },
        '&:disabled': {
          borderWidth: ownerState.size === 'large' ? 2 : 1,
          borderStyle: 'solid',
          background: theme.palette.grey.A100,
          color: theme.palette.text.disabled,
          borderColor: theme.palette.grey.A100,
        },
      }),
      sizeSmall: ({ theme }) => ({
        ...theme.typography.body2,
        fontWeight: 500,
        padding: '7.5px',
      }),
      sizeLarge: ({ theme }) => ({
        ...theme.typography.body1,
        fontWeight: 500,
        padding: '10px',
      }),

      selected: ({ theme }) => ({
        borderColor: theme.palette.primary.main,
        background: theme.palette.common.white,
      }),
    },
    // css state classes didnt work for primary, secondary, standard
    variants: [
      {
        props: { color: 'primary' },
        style: ({ theme: { palette } }) => ({
          color: palette.primary.main,
          '&.Mui-selected': {
            borderColor: palette.primary.main,
          },
        }),
      },
      {
        props: { color: 'secondary' },
        style: ({ theme: { palette } }) => ({
          color: palette.secondary.main,
          '&.Mui-selected': {
            borderColor: palette.secondary.main,
          },
        }),
      },
      {
        props: { color: 'warning' },
        style: ({ theme: { palette } }) => ({
          color: palette.warning.main,
          '&.Mui-selected': {
            borderColor: palette.warning.main,
          },
        }),
      },
      {
        props: { color: 'success' },
        style: ({ theme: { palette } }) => ({
          color: palette.success.main,
          '&.Mui-selected': {
            borderColor: palette.success.main,
          },
        }),
      },
    ],
  },
  MuiCard: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius * 3,
      }),
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(2, 2, 0, 2),
      }),
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&:last-child': { paddingBottom: theme.spacing(2) },
      }),
    },
  },
  MuiPopover: {
    defaultProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
    },
    styleOverrides: {
      paper: ({ theme }) => ({
        boxShadow: theme.shadows[2],
        padding: theme.spacing(2.5),
        borderRadius: theme.spacing(1),
        border: `1px solid ${theme.palette.grey.A100}`,
      }),
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        ':hover': {
          color: theme.palette.primary.main,
        },
      }),
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.grey[500],
        ...theme.typography.body2,
        fontWeight: 600,
      }),
      rounded: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius * 2,
      }),
      page: ({ theme }) => ({
        backgroundColor: theme.palette.grey.A100,
        margin: theme.spacing(0, 0.5),
        border: '1px solid transparent',

        '&:hover': {
          backgroundColor: theme.palette.primary.A100,
          borderColor: theme.palette.primary['50'],
          color: theme.palette.text.primary,
          boxShadow: theme.shadows[1],
        },

        '&.Mui-selected': {
          backgroundColor: theme.palette.warning['500'],
          color: theme.palette.text.primary,

          '&:hover': {
            backgroundColor: theme.palette.primary.A100,
            borderColor: theme.palette.primary['50'],
            color: theme.palette.text.primary,
            boxShadow: theme.shadows[1],
          },
        },
      }),
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }) => ({
        marginRight: theme.spacing(2),
        '& .MuiTabs-indicator': {
          display: 'none',
        },

        [`&.${TabsClass.underlined}`]: {
          width: '100%',
          marginRight: 0,
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          paddingTop: theme.spacing(2),
          borderBottom: `1px solid ${theme.palette.grey.A100}`,

          '& .MuiTab-root': {
            paddingBottom: theme.spacing(1.5),
          },
          '& .MuiTabs-flexContainer': {
            justifyContent: 'space-between',
          },

          '& .MuiTabs-indicator': {
            display: 'block',
            background: theme.palette.gradient.primary,
            height: '4px',
            borderRadius: theme.shape.borderRadius * 5,
          },
        },
      }),
    },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        border: `2px solid ${theme.palette.divider}`,
        backgroundColor: alpha(theme.palette.background.paper, 0.5),
        borderTopLeftRadius: theme.shape.borderRadius * 5,
        borderTopRightRadius: theme.shape.borderRadius * 5,
        textTransform: 'none',
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 700,
        color: theme.palette.text.secondary,
        padding: theme.spacing(1, 2),
        minHeight: theme.spacing(6),

        [`&:first-of-type:not(.${TabsClass.underlined})`]: {
          '&.Mui-selected': {
            border: 0,
            boxShadow: `-2px 2px 0 0 ${theme.palette.divider} inset, 6px 8px 8px rgba(15, 15, 15, 0.2)`,
            padding: `${theme.spacing(1)} calc(${theme.spacing(2)} + 2px)`,
          },
        },

        [`&:not(:first-of-type):not(.${TabsClass.underlined})`]: {
          marginLeft: '-2px', // overlap borders
        },

        [`&.Mui-selected:not(.${TabsClass.underlined})`]: {
          boxShadow: '6px 8px 8px rgba(15, 15, 15, 0.2)',
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          borderBottom: 0,
        },

        [`&.${TabsClass.underlined}`]: {
          border: 0,
          background: 'none',
          borderRadius: 0,
          padding: theme.spacing(1),
          fontSize: theme.typography.body1.fontSize,
          fontWeight: 500,
          minHeight: '45px',
          transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.short,
          }),

          '&.Mui-selected': {
            boxShadow: 'none',
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,
            fontWeight: 700,
          },

          '&.Mui-focusVisible': {
            borderRadius: theme.shape.borderRadius * 2,
            background: theme.palette.primary.A200,
          },
        },
      }),
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: ({ theme }) => ({
        border: 0,

        // Treedata
        [`&.${DataGridClass.treeDataTable}`]: {
          '& .MuiDataGrid-main': {
            border: `1px solid ${theme.palette.grey.A100}`,
            borderRadius: theme.spacing(1),
            '& .MuiDataGrid-columnHeaders': {
              border: 'none',
              paddingTop: theme.spacing(1),
              '& .MuiDataGrid-columnHeader': {
                '&:first-of-type': {
                  paddingLeft: theme.spacing(2),
                },
              },
            },
          },
        },
      }),

      withBorderColor: ({ theme }) => ({
        borderColor: theme.palette.divider,
      }),

      columnHeaders: ({ theme }) => ({
        borderBottom: `1px solid ${theme.palette.divider}`,
      }),

      columnHeader: {
        backgroundColor: 'transparent',
        '&:focus-within': {
          outline: 'none',
        },

        '&:not(.MuiDataGrid-columnHeader--sorted):hover .MuiDataGrid-sortIcon': {
          opacity: 1,
        },
      },

      columnHeaderTitle: ({ theme }) => ({
        ...theme.typography.body1,
        fontWeight: 700,
      }),

      columnHeaderCheckbox: () => ({
        padding: 0,
      }),

      row: ({ theme }) => ({
        border: `1px solid transparent`,

        [`& .${DataGridClass.cellIcon}`]: {
          opacity: 0,
        },

        [`& .${DataGridClass.cellLink}`]: {
          color: 'inherit',
          textDecoration: 'none',
        },

        [`&:hover`]: {
          [`& .${DataGridClass.cellIcon}`]: {
            opacity: 1,
          },

          [`& .${DataGridClass.cellLink}`]: {
            color: theme.palette.primary.main,
            textDecoration: 'underline',
          },
        },

        ':nth-of-type(odd)': {
          backgroundColor: theme.palette.info[100],
        },

        ':nth-of-type(even)': {
          backgroundColor: 'inherit',
        },

        ':last-of-type': {
          marginBottom: '2px',
          borderBottom: `1px solid ${theme.palette.divider}`,
        },

        [`&.Mui-selected`]: {
          backgroundColor: `${theme.palette.primary[100]} !important`,
          borderBottom: `1px solid ${theme.palette.divider}`,
        },

        [`&.${DataGridClass.cellClickable}`]: {
          '&:hover': {
            backgroundColor: `${theme.palette.primary[100]} !important`,
            cursor: 'pointer',
          },
        },

        // Treedata
        [`&.${DataGridClass.treeParentRow}`]: {
          background: 'inherit',
          '& .MuiDataGrid-treeDataGroupingCellToggle': {
            marginRight: theme.spacing(1),
          },

          '&:hover': { background: 'inherit' },
          '& .MuiDataGrid-cell': {
            fontWeight: 600,
            color: 'inherit',
          },
          '&:last-of-type': {
            border: 'none',
          },
        },
        [`&.${DataGridClass.treeChildRow}`]: {
          '& .MuiDataGrid-treeDataGroupingCellToggle': {
            marginRight: theme.spacing(-1),
          },
          '& .MuiDataGrid-cellContent': {
            textWrap: 'noWrap',
          },
        },

        '&.Mui-hovered': {
          background: theme.palette.primary[100],
        },
      }),

      cell: ({ theme }) => ({
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        fontWeight: 600,
        whiteSpace: 'normal !important',

        [`&.${DataGridClass.cellBold}`]: {
          fontWeight: 700,
          color: theme.palette.text.primary,
        },

        '&:focus-within': {
          outline: 'none',
        },

        // Fixes a bug when selecting
        border: 'none',
      }),

      footerContainer: ({ theme }) => ({
        ...theme.typography.body2,
        minHeight: '10px',
        paddingTop: 20,
        borderTop: `1px solid ${theme.palette.divider}`,
      }),

      pinnedColumnHeaders: ({ theme }) => ({
        marginLeft: 4,
        boxShadow: '2px 0px 4px -2px rgba(0, 0, 0, 0.21)',
        backgroundColor: theme.palette.common.white,
      }),

      pinnedColumns: ({ theme: { typography, palette } }) => ({
        backgroundColor: `${palette.common.white} !important`,

        '.MuiDataGrid-cellContent': {
          ...typography.body1,
          fontWeight: 600,
          color: palette.text.primary,
        },

        '.MuiDataGrid-row': {
          // backgroundColor: `${palette.common.white} !important`,
          marginLeft: '2px',
          borderBottom: `1px solid ${palette.divider}`,

          '&.Mui-selected': {
            backgroundColor: `${palette.common.white} !important`,
          },
        },
      }),

      panel: ({ theme }) => ({
        '& .MuiPaper-root': {
          boxShadow: theme.shadows[2],
          padding: theme.spacing(2),
          borderRadius: theme.spacing(1),
          border: `1px solid ${theme.palette.divider}`,
        },
      }),

      menu: ({ theme: { spacing, palette, shadows } }) => ({
        '& .MuiPaper-root': {
          boxShadow: shadows[2],
          padding: spacing(0.5, 1),
          borderRadius: spacing(1),
          border: `1px solid ${palette.divider}`,
        },
      }),

      toolbarContainer: ({ theme }) => ({
        '& .MuiFormControl-root .MuiInputBase-root': {
          margin: 0,
          height: 'fit-content',
          background: theme.palette.primary.A100,
          borderRadius: theme.shape.borderRadius * 2,

          '& input': {
            '::placeholder': {
              color: theme.palette.text.secondary,
              ...theme.typography.body2,
              fontWeight: 450,
            },
          },

          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: `1px solid ${theme.palette.grey.A100}`,
              borderRadius: theme.shape.borderRadius * 2,
            },
          },

          '.MuiSvgIcon-root': {
            fill: theme.palette.action.active,
          },
        },
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }) => ({
        padding: theme.spacing(4),
        borderRadius: theme.spacing(3),
        boxShadow: theme.shadows[1],
        position: 'relative',
        overflowY: 'unset',
      }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(0, 0, 2),
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(0),
        margin: theme.spacing(0, 0, 2),

        ':last-child': {
          paddingBottom: 0,
        },
      }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: () => ({
        padding: 0,
      }),
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.primary.A100,
        '&::before': { height: 0 },
        '&.active': {
          '> .MuiAccordionSummary-root': {
            color: theme.palette.primary.main,
          },
        },
      }),
      rounded: ({ theme }) => ({
        '&.MuiPaper-root': {
          borderRadius: theme.spacing(1.5),
        },
      }),
    },
    defaultProps: {
      disableGutters: true,
      TransitionProps: { unmountOnExit: true, mountOnEnter: true },
      elevation: 0,
    },
  },
  MuiAccordionSummary: {
    defaultProps: {
      expandIcon: <ExpandMore />,
    },
    styleOverrides: {
      root: {
        fontWeight: 700,
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      checkedIcon: <CheckboxCheckedIcon />,
      icon: <CheckboxUncheckedIcon />,
    },
    styleOverrides: {
      root: ({ ownerState, theme: { spacing } }) => ({
        ...(ownerState.size === 'small'
          ? {
              width: spacing(4.25),
              height: spacing(4.25),
            }
          : {}),
        // disabled-class didnt work...
        '&.Mui-disabled': {
          opacity: '0.5',
        },
      }),
    },
  },
  MuiRadio: {
    defaultProps: {
      checkedIcon: <RadioCheckedIcon />,
      icon: <RadioUncheckedIcon />,
    },
    styleOverrides: {
      root: ({ ownerState, theme: { spacing } }) => ({
        ...(ownerState.size === 'small'
          ? {
              width: spacing(4.25),
              height: spacing(4.25),
            }
          : {}),
        // disabled-class didnt work...
        '&.Mui-disabled': {
          opacity: '0.5',
        },
      }),
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: ({ theme: { spacing } }) => ({
        gap: spacing(1),
      }),
      grouped: ({ theme: { spacing, palette, shape } }) => ({
        border: `2px solid ${palette.grey.A100} !important`,
        color: palette.text.primary,
        '&.MuiToggleButtonGroup-grouped': {
          borderRadius: shape.borderRadius * 2,
          '&:hover': {
            color: palette.common.white,
            border: `2px solid ${palette.grey[900]} !important`,
            transition: 'none', // Remove for smoother border transition
          },
        },
        '&:hover': {
          transition: 'none', // Remove for smoother border transition
        },

        '&.Mui-selected': {
          '&.MuiToggleButton-primary, &.MuiToggleButton-standard': {
            borderColor: `${palette.primary.main} !important`,
            color: palette.primary.main,
          },
          '&.MuiToggleButton-secondary': {
            borderColor: `${palette.secondary.main} !important`,
            color: palette.secondary.main,
          },
          '&.MuiToggleButton-warning': {
            borderColor: `${palette.warning.main} !important`,
            color: palette.warning.main,
          },
          '&.MuiToggleButton-success': {
            borderColor: `${palette.success.main} !important`,
            color: palette.success.main,
          },
          '&:hover': {
            color: palette.common.white,
            borderWidth: '2px',
            borderStyle: 'solid',
            transition: 'none', // Remove for smoother border transition
          },
        },
        '&.MuiToggleButton-sizeSmall': {
          padding: spacing(0.5),
        },
        '&.MuiToggleButton-sizeMedium': {
          padding: spacing(1),
        },
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: ({ ownerState, theme: { palette, transitions } }) => ({
        borderWidth: `${ownerState.size === 'medium' ? 2 : 1}px `,
        borderColor: palette.grey.A100,
        transition: transitions.create(['border-color']),
      }),

      inputSizeSmall: {
        minHeight: '23px', //compensate for 14px font size
      },

      root: ({ ownerState, theme: { shape, palette, typography } }) => ({
        borderRadius:
          ownerState.size === 'medium' ? shape.borderRadius * 3 : shape.borderRadius * 2,
        background: palette.common.white,
        ...(ownerState.size === 'medium' ? typography.body1 : typography.body2),
        fontWeight: 500,

        '& input': {
          '::placeholder': {
            opacity: 0.6,
          },
        },

        // Hover
        [`&:hover .MuiOutlinedInput-notchedOutline`]: {
          borderColor: palette.primary.main,
        },

        // Focus state
        [`&.Mui-focused`]: {
          background: palette.primary.A100,

          '.MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary.main,
            borderWidth: `${ownerState.size === 'medium' ? 2 : 1}px `,
          },
        },

        // Error state
        [`&.Mui-error`]: {
          color: palette.warning[900],
          background: palette.warning[50],

          ' svg': {
            color: palette.warning[900],
          },

          '.MuiOutlinedInput-notchedOutline': {
            borderColor: palette.warning[900],
          },
        },

        // Disabled state
        [`&.Mui-disabled`]: {
          background: alpha(palette.grey.A100, 0.2),

          '.MuiOutlinedInput-notchedOutline': {
            color: palette.text.disabled,
            borderColor: palette.grey.A100,
          },

          ' svg': {
            color: palette.grey[200],
          },
        },
      }),
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme: { palette, typography } }) => ({
        ...typography.body1,
        color: palette.text.primary,
        fontWeight: 700,
        verticalAlign: 'middle',

        '.MuiFormLabel-asterisk': {
          color: palette.warning[900],
        },

        [`&.Mui-error`]: {
          color: palette.warning[900],
        },

        [`&.Mui-disabled`]: {
          color: palette.grey[200],
        },
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme: { spacing, typography, palette } }) => ({
        marginLeft: 0,
        marginTop: spacing(1),
        ...typography.body2,
        fontWeight: 500,

        [`&.Mui-error`]: {
          color: palette.warning[900],

          ' svg': {
            color: palette.warning[900],
          },
        },

        [`&.Mui-disabled`]: {
          color: palette.grey[200],

          ' svg': {
            color: palette.grey[200],
          },
        },
      }),
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme: { spacing, shape, palette, typography, shadows } }) => ({
        background: palette.grey[900],
        color: palette.common.white,
        border: `1px solid ${palette.grey[900]}`,
        borderRadius: shape.borderRadius * 3,
        padding: spacing(2),
        ...typography.body3,
        boxShadow: shadows[1],
      }),
      arrow: ({ theme: { palette } }) => ({
        color: palette.grey[900],
      }),
    },
  },
  MuiBadge: {
    styleOverrides: {
      colorPrimary: ({ theme: { palette } }) => ({
        background: palette.gradient.primary,
      }),
      colorSecondary: ({ theme: { palette } }) => ({
        background: palette.gradient.secondary,
      }),
      colorWarning: ({ theme: { palette } }) => ({
        background: palette.gradient.warning,
      }),
    },
  },
  MuiStepper: {
    styleOverrides: {
      horizontal: ({ theme: { spacing, palette, typography, shape } }) => ({
        justifyContent: 'space-between',
        '.MuiStep-root': {
          padding: spacing(0, 2.25),
          flex: 'none',

          ':first-of-type': {
            paddingLeft: 0,
          },

          ':last-of-type': {
            paddingRight: 0,
          },
        },

        '.MuiStepConnector-root': {
          position: 'inherit',
          flex: 1,
          alignSelf: 'center',
          '&.Mui-active, &.Mui-completed': {
            '.MuiStepConnector-line': {
              borderColor: palette.primary.main,
            },
          },
          '.MuiStepConnector-line': {
            borderTopWidth: '2px',
            borderColor: palette.grey[400],
            minWidth: '10px',
          },
        },

        '.MuiStepLabel-root': {
          padding: spacing(1, 2),
          overflow: 'hidden',
          border: `2px solid transparent`,
          borderRadius: shape.borderRadius * 3,
          backgroundColor: alpha(palette.grey.A100, 0.3),

          ' svg': {
            width: '16px',
            height: '16px',
            fill: palette.grey[400],
          },

          '.MuiStepLabel-label': {
            color: palette.grey[400],
            ...typography.body2,
            fontWeight: 700,
            marginTop: spacing(1),
          },

          '&.Mui-active, &.Mui-completed': {
            ' svg': {
              fill: palette.primary.main,
            },
            borderColor: palette.primary.main,
            '.MuiStepLabel-label': {
              color: palette.primary.main,
            },
          },

          '&.Mui-completed': {
            backgroundColor: alpha(palette.grey.A100, 0.3),
            border: `2px solid transparent`,
          },

          '&.Mui-active': {
            background: palette.common.white,
          },
        },
      }),
    },
  },
  MuiSelect: {
    defaultProps: {
      IconComponent: ExpandMoreRounded,
    },
    styleOverrides: {
      select: () => ({
        minWidth: '100px',
      }),
      root: ({ ownerState }) => ({
        '.MuiOutlinedInput-input': {
          padding: '16px 14px', // compensate for 2px border
        },

        '.MuiInputBase-inputSizeSmall': {
          padding: '8.5px 14px', // compensate for 14px font size
          minHeight: '23px',
        },
      }),
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: ({ theme: { spacing, palette, typography } }) => ({
        '& .MuiMenu-list': {
          padding: 0,

          '.MuiListSubheader-root': {
            ...typography.body2,
            fontWeight: 700,
            color: palette.primary.main,
            padding: spacing(0, 1, 1),
          },
        },
      }),
      paper: ({ theme: { shape, spacing } }) => ({
        padding: spacing(2),
        borderRadius: shape.borderRadius * 3,
      }),
      list: {
        padding: 0,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme: { shape, spacing, palette } }) => ({
        padding: spacing(1) + '!important',
        fontWeight: 500,
        borderRadius: shape.borderRadius * 3,
        '&.Mui-selected': {
          background: palette.primary[50],
          '&.Mui-focusVisible': {
            background: palette.primary[50],
          },
        },
        '&:hover, &:focus, &.Mui-focused': {
          background: palette.primary.A100 + '!important',
        },
        '&.Mui-disabled': {
          background: 'transparent',
        },
      }),
    },
  },
  MuiSwitch: {
    styleOverrides: {
      // for the internal base (/thumb in relation to the track)
      switchBase: {
        padding: 0,
        margin: 5,
        opacity: 1,
        // check state for track
        '&.Mui-checked': {
          '& + .MuiSwitch-track': {
            opacity: 1,
          },
        },

        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.6,
        },
      },
      // unchecked state
      track: ({ theme: { palette } }) => ({
        background: palette.grey['400'],
        opacity: 1,

        border: 0,
        borderRadius: 30 / 2,
      }),
      thumb: ({ theme: { palette } }) => ({
        background: palette.common.white,

        width: 20,
        height: 20,
        boxSizing: 'border-box',
      }),
      sizeSmall: ({ theme: { palette } }) => ({
        height: 25,
        width: 41,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 3.5,
          opacity: 1,
          background: 'red',

          '&.Mui-checked': {
            '& + .MuiSwitch-track': {
              opacity: 1,
            },
          },

          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.6,
          },
        },
        '& .MuiSwitch-track': {
          background: palette.grey['400'],

          borderRadius: 25 / 2,
          border: 0,
        },
        '& .MuiSwitch-thumb': {
          width: 18,
          height: 18,
        },
      }),
      sizeMedium: {
        height: 30,
        width: 50,
        padding: 0,
      },
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      popupIcon: <ExpandMoreRounded />,
    },
    styleOverrides: {
      paper: ({ theme: { shape, shadows, spacing, palette } }) => ({
        boxShadow: shadows[4],
        padding: spacing(2),
        borderRadius: shape.borderRadius * 3,
        border: `1px solid ${palette.grey.A100}`,
      }),
      listbox: {
        padding: 0,
      },
      option: ({ theme: { shape, typography, palette, spacing } }) => ({
        ...typography.body2,
        fontWeight: 500,
        padding: spacing(1) + '!important',
        borderRadius: shape.borderRadius * 3,
        '&.Mui-selected': {
          background: palette.primary[50],
          '&.Mui-focusVisible': {
            background: palette.primary[50],
          },
        },
        '&:hover, &:focus, &.Mui-focused': {
          background: palette.primary.A100 + '!important',
        },
        '&.Mui-disabled': {
          background: 'transparent',
        },
      }),
    },
  },
  MuiLink: {
    styleOverrides: {
      root: () => ({
        letterSpacing: 'inherit',
      }),
    },
  },
};
