import { useBrandTFunction } from '@dap-admin/i18n';
import { Dataowner, QueryStatus } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { MenuWithPopup } from '@dap-common/ui';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { ListItemIcon, MenuItem, Tooltip, Typography } from '@mui/material';
import {
  AddEntityIcon,
  ProhibitEntityIcon,
  SuperAdminIcon,
  TrashIcon,
  UnlockEntityIcon,
} from '@shared/custom-icons';
import { useEffect, useState } from 'react';

interface Props {
  dataowner: Dataowner;
  actions: DataownerDetailsMenuActions;
  isSuperuser: boolean;
}

export interface DataownerDetailsMenuActions {
  edit: {
    onOpen: () => void;
    isOpen: boolean;
  } & QueryStatus;
  delete: { onOpen: () => void; isOpen: boolean } & QueryStatus;
  newLocation: { onOpen: () => void; isOpen: boolean };
  enableDisable: { onOpen: () => void; isOpen: boolean };
}

export default function DataownerDetailsBlockActionMenu({
  actions,
  dataowner,
  isSuperuser,
}: Props) {
  const tApp = useAppTFunction();
  const tBrand = useBrandTFunction(dataowner.brand.key);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    if (
      actions.edit.isOpen ||
      actions.delete.isOpen ||
      actions.newLocation.isOpen ||
      actions.enableDisable.isOpen
    ) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  }, [
    actions.edit.isOpen,
    actions.delete.isOpen,
    actions.newLocation.isOpen,
    actions.enableDisable.isOpen,
  ]);

  return (
    <div>
      <MenuWithPopup isOpen={isMenuOpen}>
        <MenuItem onClick={actions.edit.onOpen}>
          <ListItemIcon>
            <EditRoundedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="body2">{tApp('dataowner.actions.editDetails')}</Typography>
        </MenuItem>

        <MenuItem onClick={actions.newLocation.onOpen}>
          <ListItemIcon>
            <AddEntityIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="body2">{tBrand('brand.location.create', 'capitalize')}</Typography>
        </MenuItem>

        <MenuItem onClick={actions.enableDisable.onOpen}>
          <ListItemIcon>
            {dataowner.enabled ? (
              <ProhibitEntityIcon fontSize="small" />
            ) : (
              <UnlockEntityIcon fontSize="small" />
            )}
          </ListItemIcon>
          <Typography variant="body2">
            {dataowner.enabled
              ? tApp('dataowner.actions.deactivate', {
                  dataowner: tBrand('brand.dataowner.name.singular'),
                })
              : tApp('dataowner.actions.activate', {
                  dataowner: tBrand('brand.dataowner.name.singular'),
                })}
          </Typography>
        </MenuItem>

        {isSuperuser &&
          (!dataowner.locations?.length ? (
            <MenuItem onClick={actions.delete.onOpen}>
              <ListItemIcon>
                <TrashIcon color="warning" fontSize="small" />
              </ListItemIcon>
              <Typography variant="body2">
                {tApp('dataowner.actions.delete', {
                  dataowner: tBrand('brand.dataowner.name.singular'),
                })}
              </Typography>
            </MenuItem>
          ) : (
            <Tooltip
              title={tApp('dataowner.actions.deleteTooltip', {
                locations: tBrand('brand.location.name.plural'),
                dataowner: tBrand('brand.dataowner.name.definite'),
              })}
            >
              <MenuItem
                disabled
                sx={{
                  '&.Mui-disabled': {
                    pointerEvents: 'auto',
                    ':hover': {
                      bgcolor: 'transparent',
                    },
                  },
                }}
              >
                <ListItemIcon>
                  <SuperAdminIcon color="error" />
                </ListItemIcon>
                <Typography variant="body2">
                  {tApp('dataowner.actions.delete', {
                    dataowner: tBrand('brand.dataowner.name.singular'),
                  })}
                </Typography>
              </MenuItem>
            </Tooltip>
          ))}
      </MenuWithPopup>
    </div>
  );
}
