import { yupSchema } from '@dap-admin/schemas';
import { Dataowner } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { Validations } from '@dap-common/utils';
import { parseISO } from 'date-fns';
import { useMemo } from 'react';
import * as yup from 'yup';

export const useEditDataownerSchema = (dataowner: Dataowner) => {
  return yup.object({
    details: useEditDataownerDetailsSchema(dataowner),
    services: useEditDataownerAppsSchema(dataowner.apps),
  });
};

export const useEditDataownerDetailsSchema = (dataowner: Dataowner) => {
  const tApp = useAppTFunction();

  const schema = useMemo(
    () =>
      yup.object({
        name: yup.string().required(tApp('validation.required')).default(dataowner.name),
        country: yup.string().default(dataowner.country),
        orgNr: Validations.orgNo(tApp)
          .default(dataowner.orgNr)
          .required(tApp('validation.required')),
        notMember: yup.boolean().default(!dataowner.isMember),
        memberFrom: Validations.date(tApp)
          .default(dataowner.memberFrom ? parseISO(dataowner.memberFrom) : null)
          .when('notMember', {
            is: false,
            then: Validations.date(tApp)
              .default(dataowner.memberFrom ? parseISO(dataowner.memberFrom) : null)
              .required(tApp('validation.required')),
          }),
        memberTo: Validations.date(tApp)
          .min(yup.ref('memberFrom'), tApp('validation.dateBeforeMinDate'))
          .default(dataowner.memberTo ? parseISO(dataowner.memberTo) : null)
          .when('notMember', {
            is: false,
            then: Validations.date(tApp)
              .min(yup.ref('memberFrom'), tApp('validation.dateBeforeMinDate'))
              .default(dataowner.memberFrom ? parseISO(dataowner.memberFrom) : null),
          }),
        glns: yupSchema.glns(tApp).glns.default(dataowner.glns.map(({ id }) => ({ id: id }))),
      }),
    [dataowner, tApp]
  );

  return schema;
};

export const useEditDataownerAppsSchema = (apps: Dataowner['apps']) => {
  const schema = useMemo(
    () =>
      yup.object({
        apps: yup.array(yup.string().default('')).default(apps.map(({ key }) => key)),
      }),
    [apps]
  );

  return schema;
};

export type EditDataownerSchema = yup.InferType<ReturnType<typeof useEditDataownerSchema>>;
