interface BrandAdminConfig {
  iam: {
    url: string;
  };
}

type Env = 'dev' | 'stage' | 'prod' | 'local';

interface EnvConfigs {
  dev: BrandAdminConfig;
  stage: BrandAdminConfig;
  prod: BrandAdminConfig;
}

const envConfigs: EnvConfigs = {
  dev: { iam: { url: 'https://iam.datahub-dev.mestergruppen.cloud' } },
  stage: { iam: { url: 'https://iam.datahub-stage.mestergruppen.cloud' } },
  prod: { iam: { url: 'https://iam.datahub-prod.mestergruppen.cloud' } },
};
const env = (process.env['NX_PUBLIC_REACT_APP_ENV'] || 'local') as Env;

export const brandAdminConfig = env === 'local' ? envConfigs.dev : envConfigs[env];
