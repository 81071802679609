import { useAppTFunction } from '@dap-common/i18n';
import * as yup from 'yup';
import yupSchema from './schemas';
import { useMemo } from 'react';

export const useBrandNameSchema = (currentBrandName: string) => {
  const t = useAppTFunction();
  const brandNameSchema = useMemo(
    () =>
      yup
        .object({
          name: yupSchema.name(t).name.required(t('validation.required')).default(currentBrandName),
        })
        .defined(),
    [t, currentBrandName]
  );
  return brandNameSchema;
};

export type BrandNameSchema = yup.InferType<ReturnType<typeof useBrandNameSchema>>;
