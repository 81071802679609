import { useAppTFunction } from '@dap-common/i18n';
import { FlexColumn } from '@dap-common/ui';
import { Breadcrumbs, LabelValueTable, Paper, TagHeading } from '@shared/components';
import BrandDetailsBlockActionMenu, {
  BrandDetailsMenuActions,
} from '../BrandDetailsBlockActionMenu';

interface Props {
  brandName: string;
  brandKey: string;
  isSuperuser: boolean;
  actions: BrandDetailsMenuActions;
}

export default function BrandDetailsBlock({ brandName, brandKey, isSuperuser, actions }: Props) {
  const tApp = useAppTFunction();

  return (
    <Paper data-testid="BrandDetailsBlock" sx={{ height: '100%' }}>
      <TagHeading marginBottom={3} alignItems="flex-start" color="success">
        <FlexColumn>
          <Breadcrumbs
            items={[
              { href: '../..', label: tApp('common.home') },
              { label: tApp('common.navigation.brandDetails') },
            ]}
            fontSize="sm"
          />
        </FlexColumn>

        {isSuperuser && <BrandDetailsBlockActionMenu brandName={brandName} actions={actions} />}
      </TagHeading>

      <LabelValueTable
        items={[
          {
            label: tApp('common.brand.id'),
            value: brandKey,
          },
          {
            label: tApp('brandDetailsPage.name'),
            value: brandName,
          },
        ]}
      />
    </Paper>
  );
}
