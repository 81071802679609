import { Theme, alpha } from '@mui/material';
import themeV2 from '../dap';
import { TabsClass } from '../dap/classes';
import { dataGridBoldClass } from './styleConstants';

export const componentsStyles: Theme['components'] = {
  ...themeV2.components,
  MuiDataGrid: {
    styleOverrides: {
      root: ({ theme: { palette, typography } }) => ({
        border: 0,
        borderRadius: 16,
        '.MuiDataGrid-columnHeader[role=columnheader]': {
          ...typography.body1,
          fontWeight: 500,
          marginTop: 6,
        },
        '.MuiDataGrid-cell[role=cell]': {
          ...typography.body2,
          color: palette.action.active,
          fontWeight: 400,
          whiteSpace: 'normal !important',
          [`&.${dataGridBoldClass}`]: {
            overflow: 'visible',
            fontWeight: 500,
            color: palette.text.primary,
          },
        },
        '.MuiDataGrid-footerContainer': {
          marginTop: 6,
          justifyContent: 'center',
        },
        '.MuiDataGrid-columnHeaders': {
          backgroundColor: alpha(`${palette.success['A700']}`, 0.15),
          paddingLeft: 16,
        },
        '.MuiDataGrid-virtualScrollerContent': {
          marginTop: '-3px',
        },
        '.MuiDataGrid-pinnedColumns--left': {
          '.MuiDataGrid-row': {
            marginLeft: '2px',
            marginRight: '2px',
          },
        },
      }),
      columnHeaderTitle: ({ theme: { typography } }) => ({
        ...typography.body1,
        fontWeight: 600,
      }),
      pinnedColumnHeaders: ({ theme: { palette } }) => ({
        boxShadow: '-2px 0px 4px -2px rgba(0, 0, 0, 0.21) !important',
        backgroundColor: palette.success.A400, // Similar green to alpha(`${palette.success['A700']}`, 0.15), but cannot use alpha or hex with alpha channels because of transparency
      }),
      pinnedColumns: ({ theme: { palette } }) => ({
        backgroundColor: `${palette.common.white} !important`,
        '.MuiDataGrid-row': {
          paddingLeft: 0,
          backgroundColor: `${palette.common.white} !important`,
          '.MuiDataGrid-cell': {
            borderTop: `1px solid ${palette.divider}`,
          },
          '&.Mui-hovered': {
            backgroundColor: `${palette.common.white} !important`,
          },
        },
      }),
      columnHeader: {
        backgroundColor: 'transparent',
        '&:focus-within': {
          outline: 'none',
        },

        '&:not(.MuiDataGrid-columnHeader--sorted):hover .MuiDataGrid-sortIcon': {
          opacity: 1,
        },
      },
      row: ({ theme: { palette } }) => ({
        overflow: 'hidden',
        paddingLeft: 16,
        ':first-of-type': {
          marginTop: '2px',
        },
        ':nth-of-type(odd)': {
          backgroundColor: palette.background.secondary,
          '&:hover': { background: palette.background.secondary },
        },
        ':nth-of-type(even)': {
          backgroundColor: 'inherit',
        },
        ':last-of-type': {
          marginBottom: '2px',
          borderBottom: `1px solid ${palette.divider}`,
        },
      }),
    },
  },

  MuiPaginationItem: {
    styleOverrides: {
      root: ({ theme: { palette, typography, shape, spacing } }) => ({
        color: palette.common.black,
        ...typography.body2,
        fontWeight: 600,
        borderRadius: shape.borderRadius,
        '&.MuiButtonBase-root': {
          '& > :first-of-type': {
            outline: `2px solid ${palette.divider}`,
            borderRadius: shape.borderRadius * 5,
            margin: spacing(1.5),
          },
        },
      }),
      page: ({ theme: { palette } }) => ({
        '&.Mui-selected': {
          background: palette.gradient.primary,
          color: palette.common.white,
        },
      }),
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: () => ({
        fontWeight: `${400} !important`,
      }),
      shrink: ({ ownerState }) => ({
        ...(ownerState.shrink && {
          fontSize: '16px !important',
        }),
      }),
    },
  },

  MuiSwitch: {
    styleOverrides: {
      root: ({ theme: { palette, shape, transitions } }) => ({
        width: 45,
        height: 25,
        padding: 0,

        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2.5,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(20px)',
            '& + .MuiSwitch-track': {
              backgroundColor: palette.primary[400],
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: palette.grey[100],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          color: palette.common.white,
          width: 20,
          height: 20,
        },
        '& .MuiSwitch-track': {
          borderRadius: shape.borderRadius * 4,
          backgroundColor: palette.grey[200],
          opacity: 1,
          transition: transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }),
    },
  },
  MuiMenu: {
    defaultProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    },
    styleOverrides: {
      root: ({ theme: { spacing, palette } }) => ({
        '& .MuiPaper-root': {
          padding: spacing(1),
          border: `2px solid ${palette.background.default}`,
          borderRadius: spacing(2),
        },
        '& .MuiMenu-list': {
          padding: 0,
          '& .MuiListSubheader-root:first-of-type': {
            lineHeight: 1,
            marginBottom: spacing(2),
          },
          '& .MuiDivider-root': {
            marginTop: spacing(2),
            marginBottom: spacing(2),
            border: `1px solid ${palette.divider}`,
          },
        },
      }),
    },
  },

  MuiMenuItem: {
    defaultProps: {},
    styleOverrides: {
      root: ({ ownerState, theme: { spacing, palette, shape } }) => ({
        gap: spacing(3),
        justifyContent: 'space-between',
        padding: `${spacing(1.5)} ${spacing(2.5)}`,
        backgroundColor: palette.background.secondary,
        border: `1px solid ${palette.divider}`,
        ':hover': {
          backgroundColor: palette.divider,
        },
        ':focus': {
          backgroundColor: palette.divider,
        },
        ':mouseOver': {
          backgroundColor: palette.divider,
        },
        ':where(:not(.MuiMenuItem-root) + .MuiMenuItem-root), :first-of-type': {
          borderTopLeftRadius: shape.borderRadius * 3,
          borderTopRightRadius: shape.borderRadius * 3,
        },
        ':has(+ :not(.MuiMenuItem-root)), :last-of-type': {
          borderBottomLeftRadius: shape.borderRadius * 3,
          borderBottomRightRadius: shape.borderRadius * 3,
        },
        ...(ownerState.color === 'warning' && {
          backgroundColor: palette.warning.main,
          ':hover': {
            backgroundColor: palette.warning.dark,
          },
          ':focus': {
            backgroundColor: palette.warning.dark,
          },
        }),
        '& .MuiSvgIcon-root': {
          fill: palette.primary.main,
        },
      }),
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: ({ ownerState, theme: { typography, palette } }) => ({
        minWidth: 240,

        '& .MuiFormLabel-root': {
          ...(ownerState.size === 'medium' ? typography.body1 : typography.body2),
          fontWeight: 600,
        },
        '& .MuiInputBase-input': {
          height: '20px',
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused': {
            background: palette.common.white,
          },
        },
        '& .MuiOutlinedInput-input': {
          '&:-webkit-autofill': {
            WebkitBoxShadow: ' 0 0 0 30px white inset !important;',
          },
        },
      }),
    },
  },
  MuiTable: {
    styleOverrides: {
      root: ({ theme: { typography } }) => ({
        '& .MuiTableHead-root': {
          '& .MuiTableCell-root': { ...typography.body1, fontWeight: 'bold' },
        },
        '& .MuiTableCell-root': {
          ...typography.body2,
          fontWeight: '500',
        },
        '& .MuiTableBody-root': {
          '& .MuiTypography-root': { fontWeight: '500' },
        },
      }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme: { typography, spacing } }) => ({
        ...typography.h6,
        padding: spacing(0, 0, 3),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }),
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }) => ({
        marginRight: theme.spacing(1),
        '& .MuiTabs-indicator': {
          display: 'none',
        },

        [`&.${TabsClass.underlined}`]: {
          width: '100%',
          minHeight: 0,
          marginRight: 0,
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          borderBottom: `1px solid ${theme.palette.grey.A100}`,
          '& .MuiTab-root': {
            minHeight: '10px',
            paddingTop: 0,
            paddingBottom: 12,
          },

          '& .MuiTabs-flexContainer': {
            justifyContent: 'start',
          },

          '& .MuiTabs-indicator': {
            display: 'block',
            background: theme.palette.gradient.primary,
            height: '4px',
            borderRadius: theme.shape.borderRadius * 5,
          },
        },
      }),
    },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: alpha(theme.palette.background.paper, 0.5),
        borderTopLeftRadius: theme.shape.borderRadius * 5,
        borderTopRightRadius: theme.shape.borderRadius * 5,
        textTransform: 'none',
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.text.secondary,
        padding: theme.spacing(1, 6),
        minHeight: theme.spacing(6),

        [`:not(.${TabsClass.underlined})`]: {
          backgroundColor: theme.palette.grey[50],
        },

        [`&.Mui-selected:not(.${TabsClass.underlined})`]: {
          boxShadow: '6px 8px 8px rgba(15, 15, 15, 0.2)',
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          borderBottom: 0,
          fontWeight: 700,
        },

        [`&.${TabsClass.underlined}`]: {
          border: 0,
          padding: theme.spacing(2),

          '&.Mui-selected': {
            color: theme.palette.text.primary,
            fontWeight: 700,
          },
        },
      }),
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&::before': { height: 0 },
        '&.active': {
          '> .MuiAccordionSummary-root': {
            color: theme.palette.primary.main,
          },
        },
      }),
    },
    defaultProps: {
      disableGutters: true,
      TransitionProps: { unmountOnExit: true, mountOnEnter: true },
      elevation: 0,
    },
  },
};
