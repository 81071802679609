import { useBrandTFunction } from '@dap-admin/i18n';
import { UpdateDataownerEnabledSchema } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { ContainedButton, Dialog } from '@shared/components';

interface Props {
  open: boolean;
  cancelEdit: () => void;
  name: string;
  enabled: boolean;
  brandKey: string;
  updateDataowner: (input: UpdateDataownerEnabledSchema) => void;
  isUpdatingDataowner: boolean;
}

const DisableEnableDataowner = ({
  open,
  cancelEdit,
  name,
  enabled,
  brandKey,
  updateDataowner,
  isUpdatingDataowner,
}: Props) => {
  const t = useAppTFunction();
  const tBrand = useBrandTFunction(brandKey);

  const onSubmit = () => {
    const enabledDataowner = { enabled: !enabled };
    updateDataowner(enabledDataowner);
    cancelEdit();
  };

  const dialogAction = enabled
    ? t('dataowner.actions.deactivate', {
        dataowner: tBrand('brand.dataowner.name.singular'),
      })
    : t('dataowner.actions.activate', {
        dataowner: tBrand('brand.dataowner.name.singular'),
      });

  const dialogContent = enabled ? (
    <Alert severity="warning">
      <Typography>
        {t('dataowner.deactivate.info', {
          theLocations: tBrand('brand.location.name.plural'),
          theDataowner: tBrand('brand.dataowner.name.definite'),
        })}
      </Typography>
    </Alert>
  ) : (
    <Typography>{`${t('location.activate.info')} ${name}?`}</Typography>
  );

  return (
    <Dialog open={open} onClose={cancelEdit}>
      <DialogTitle>{dialogAction}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <DialogActions>
          <Button variant="text" size="large" color="grey" onClick={cancelEdit} disableElevation>
            {t('common.cancel')}
          </Button>
          <ContainedButton
            color={enabled ? 'warning' : 'primary'}
            loading={isUpdatingDataowner}
            onClick={onSubmit}
          >
            {dialogAction}
          </ContainedButton>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default DisableEnableDataowner;
