import { appLogos } from '@assets/appLogos';
import { AppBasic } from '@dap-admin/types';
import { ListItemIcon, ListItemProps, ListItemText } from '@mui/material';
import { Avatar } from '@shared/components';
import OutlinedListItem from './OutlinedListItem';

interface Props {
  app: AppBasic;
  secondaryAction: ListItemProps['secondaryAction'];
}

export default function AppCheckboxListItem({ app, secondaryAction }: Props) {
  return (
    <OutlinedListItem
      key={app.key}
      marginBottom="none"
      borderRadius={0}
      secondaryAction={secondaryAction}
    >
      <ListItemIcon>
        <Avatar name={app.name} imgSrc={appLogos[app.key]} sx={{ width: 25, height: 25 }} />
      </ListItemIcon>
      <ListItemText>{app.name}</ListItemText>
    </OutlinedListItem>
  );
}
