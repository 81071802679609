import { ErpSchema, useErpSchema } from '@dap-admin/schemas';
import { ErpInfo, ErpType, LocationErpInfoSchema } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { EditCard, SaveOrCancelEdit } from '@dap-common/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { LabelValueTable, SelectInputField, TextInputField } from '@shared/components';
import { pruneObject } from '@shared/utils';
import { FunctionComponent, useState } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';

interface Props {
  erpInfo: ErpInfo;
  save: (updatedErpInfo: LocationErpInfoSchema) => void;
  saving: boolean;
  isBrandAdmin: boolean;
}

const ErpInfoCard: FunctionComponent<Props> = ({ erpInfo, save, saving, isBrandAdmin }) => {
  const t = useAppTFunction();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const form = useForm<ErpSchema>({
    defaultValues: useErpSchema(erpInfo).getDefault(),
    resolver: yupResolver(useErpSchema(erpInfo)),
  });
  const { handleSubmit, control, reset } = form;
  const brandIdController = useController({ name: 'brandId', control });
  const fileGroupController = useController({ name: 'fileGroup', control });
  const erpTypeController = useController({ name: 'erpType', control });
  const warehouseIdController = useController({ name: 'warehouseId', control });

  const onSubmit = handleSubmit((erpInfo) => {
    const body: LocationErpInfoSchema['erpInfo'] = {
      ...erpInfo,
      erpType: erpInfo.erpType as ErpType,
    };
    save({ erpInfo: pruneObject(body, null) });
    setIsEditing(false);
  });

  const onCancel = () => {
    setIsEditing(false);
    reset();
  };
  return (
    <EditCard
      header={t('location.detail.erp.name')}
      isEditing={isEditing}
      edit={() => setIsEditing(true)}
      cancel={onCancel}
      isEditable={isBrandAdmin}
    >
      {isEditing ? (
        <FormProvider {...form}>
          <Stack spacing={2} pt={0.5}>
            <TextInputField
              {...brandIdController.field}
              error={brandIdController.fieldState.error?.message}
              label={t('location.detail.erp.brandId')}
              fullWidth
            />

            <TextInputField
              {...fileGroupController.field}
              error={fileGroupController.fieldState.error?.message}
              label={t('location.detail.erp.fileGroup')}
              fullWidth
            />

            <SelectInputField
              {...erpTypeController.field}
              label={t('location.detail.erp.erpType')}
              menuItems={Object.keys(ErpType).map((erpType) => ({
                name: erpType,
                value: erpType,
              }))}
              fullWidth
            />

            <TextInputField
              {...warehouseIdController.field}
              error={warehouseIdController.fieldState.error?.message}
              label={t('location.detail.erp.warehouseId')}
              fullWidth
            />

            <SaveOrCancelEdit save={onSubmit} cancel={onCancel} align="right" loading={saving} />
          </Stack>
        </FormProvider>
      ) : (
        <LabelValueTable
          items={[
            {
              label: t('location.detail.erp.brandId'),
              value: erpInfo?.brandId,
            },
            {
              label: t('location.detail.erp.fileGroup'),
              value: erpInfo?.fileGroup,
            },
            {
              label: t('location.detail.erp.erpType'),
              value: erpInfo?.erpType,
            },

            {
              label: t('location.detail.erp.warehouseId'),
              value: erpInfo?.warehouseId,
            },
          ]}
        />
      )}
    </EditCard>
  );
};

export default ErpInfoCard;
