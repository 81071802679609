import { appLogos } from '@assets/appLogos';
import { AppBasic } from '@dap-admin/types';
import { useServiceFilters } from '@dap-admin/utils';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexRow } from '@dap-common/ui';
import { List, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { Avatar, InfoTooltip } from '@shared/components';
import OutlinedListItem from './OutlinedListItem';

interface Props {
  services: Array<AppBasic>;
}

export default function ServicesList({ services }: Props) {
  const t = useAppTFunction();

  const { defaultServices, filteredServices } = useServiceFilters(services);

  return (
    <Stack gap={4} data-testid="services-list-block">
      <Stack>
        <Typography variant="h6">{t('brandadmin.services.title')}</Typography>
        <List>
          {filteredServices.length > 0 ? (
            filteredServices.map((service) => (
              <OutlinedListItem key={service.key} marginBottom="none" borderRadius={2}>
                <ListItemIcon>
                  <Avatar
                    name={service.name}
                    imgSrc={appLogos[service.key]}
                    sx={{ width: 25, height: 25 }}
                  />
                </ListItemIcon>
                <ListItemText primary={service.name} primaryTypographyProps={{ fontWeight: 500 }} />
              </OutlinedListItem>
            ))
          ) : (
            <ListItemText>{t('location.detail.services.noneSelected')}</ListItemText>
          )}
        </List>
      </Stack>
      <Stack>
        <FlexRow alignItems={'center'}>
          <Typography variant="h6">{t('location.detail.services.default.title')}</Typography>
          <InfoTooltip tooltipText={t('location.detail.services.default.description')} />
        </FlexRow>
        <List>
          {defaultServices.length > 0 ? (
            defaultServices.map((service) => (
              <OutlinedListItem key={service.key} marginBottom="none" borderRadius={2}>
                <ListItemIcon>
                  <Avatar
                    name={service.name}
                    imgSrc={appLogos[service.key]}
                    sx={{ width: 25, height: 25 }}
                  />
                </ListItemIcon>
                <ListItemText primary={service.name} primaryTypographyProps={{ fontWeight: 500 }} />
              </OutlinedListItem>
            ))
          ) : (
            <ListItemText>{t('location.detail.services.noneSelected')}</ListItemText>
          )}
        </List>
      </Stack>
    </Stack>
  );
}
