enum routes {
  root = '/',
  logout = 'logout',
  notFound = '404',
  error = 'error',
  brandadmin = 'brandadmin',
  brandDetails = 'branddetails',
  changelog = 'changelog',
  module = 'module',
  moduleSlug = ':moduleSlug',
  fremdriftCategorySlug = ':fremdriftCategorySlug',
  fremdriftArticleSlug = ':fremdriftArticleSlug',
  location = 'location',
  locations = 'locations',
  locationKey = ':locationKey',
  employees = 'employees',
  dataowner = 'dataowner',
  integrations = 'integrations',
  details = 'details',
  properties = 'properties',
  administrators = 'administrators',
  dataownerKey = ':dataownerKey',
  employee = 'employee',
  employeeId = ':employeeId',
  configurations = 'configurations',
  home = '',
  news = 'news',
  services = 'services',
  events = 'events',
  proffSlug = ':proffSlug',
  slug = ':slug',
  proffCategorySlug = ':proffCategorySlug',
  proffArticleSlug = ':proffArticleSlug',
  me = 'me',
  helpCentre = 'help',
  mosaic = 'mosaic',
  mosaicSlug = ':mosaicSlug',
  order = 'order',
  orderSlug = ':orderSlug',
  orderSummary = 'summary',
  orderCategorySlug = ':orderCategorySlug',
  organizationView = 'organization',
  contact = 'contact',
  sanity = 'sanity',
  sanityStudioDAP = 'dap-studio',
}

export default routes;
