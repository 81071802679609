import { Link, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { BreadcrumbChevron } from './BreadcrumbChevron';

export interface BreadcrumbsProps {
  fontSize?: 'sm' | 'md';
  items: {
    label: string | React.ReactNode;
    href?: string;
    relativePath?: boolean;
  }[];
}

function Breadcrumbs({ items, fontSize }: BreadcrumbsProps) {
  const variant = fontSize === 'sm' ? 'h6' : 'h4';

  return (
    <MuiBreadcrumbs
      separator={
        <Typography sx={{ alignItems: 'center' }}>
          <BreadcrumbChevron />
        </Typography>
      }
    >
      {items.map(({ href, label, relativePath }, index) => {
        const color = index === items.length - 1 ? 'text.primary' : 'text.secondary';
        return href ? (
          <Link
            key={href}
            component={RouterLink}
            underline="hover"
            to={href}
            variant={variant}
            color={color}
            {...(relativePath && { relative: 'path' })}
            sx={{ fontWeight: 500 }}
          >
            {label}
          </Link>
        ) : (
          <Typography key="href" variant={variant} color={color} fontWeight={600}>
            {label}
          </Typography>
        );
      })}
    </MuiBreadcrumbs>
  );
}

export default Breadcrumbs;
