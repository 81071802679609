import { Dialog, TagHeading, TextInputField } from '@shared/components';

import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { SaveOrCancelEdit } from '@dap-common/ui';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useAppTFunction } from '@dap-common/i18n';
import { yupResolver } from '@hookform/resolvers/yup';
import { BrandNameSchema, useBrandNameSchema } from '@dap-admin/schemas';

interface Props {
  brandName: string;
  onClose: () => void;
  onSave: (input: BrandNameSchema) => void;
}

export default function EditBrandDialog({ brandName, onClose, onSave }: Props) {
  const t = useAppTFunction();

  const form = useForm<BrandNameSchema>({
    defaultValues: useBrandNameSchema(brandName).getDefault(),
    resolver: yupResolver(useBrandNameSchema(brandName)),
  });
  const { handleSubmit, control } = form;
  const brandNameController = useController({ name: 'name', control });

  return (
    <Dialog open maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>
        <TagHeading alignItems="start" color="success">
          {brandName}
        </TagHeading>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <TextInputField
            {...brandNameController.field}
            error={brandNameController.fieldState.error?.message}
            label={t('createPage.brand.brandName')}
            fullWidth
          />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <SaveOrCancelEdit
          size={'large'}
          save={handleSubmit(onSave)}
          cancel={onClose}
          align="right"
        />
      </DialogActions>
    </Dialog>
  );
}
