import { useAppTFunction } from '@dap-common/i18n';
import { MenuWithPopup } from '@dap-common/ui';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { TrashIcon } from '@shared/custom-icons';
import { useDisclosure } from '@shared/hooks';
import { useEffect, useState } from 'react';
import DeleteBrandDialog from './BrandDetails/DeleteBrandDialog';
import EditBrandDialog from './BrandDetails/EditBrandDialog';
import { BrandNameSchema } from '@dap-admin/schemas';

interface Props {
  brandName: string;
  actions: BrandDetailsMenuActions;
}

export interface BrandDetailsMenuActions {
  onEdit: (formData: any) => void;
  isUpdating: boolean;
  onDelete: () => void;
  isDeleting: boolean;
}

export default function BrandDetailsBlockActionMenu({ brandName, actions }: Props) {
  const tApp = useAppTFunction();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const editDialog = useDisclosure();
  const deleteDialog = useDisclosure();

  useEffect(() => {
    if (deleteDialog.isOpen || editDialog.isOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  }, [deleteDialog.isOpen, editDialog.isOpen]);

  const onDelete = () => {
    deleteDialog.onClose();
    actions.onDelete();
  };

  const onSave = (data: BrandNameSchema) => {
    editDialog.onClose();
    actions.onEdit(data);
  };

  return (
    <div>
      <MenuWithPopup isOpen={isMenuOpen}>
        <MenuItem onClick={editDialog.onOpen}>
          <ListItemIcon>
            <EditRoundedIcon fontSize={'small'} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
            {tApp('location.actionMenu.editDetails')}
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={deleteDialog.onOpen}>
          <ListItemIcon>
            <TrashIcon fontSize={'small'} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
            {tApp('brandadmin.delete', {
              instance: tApp('brandadmin.brand.title.singular'),
            })}
          </ListItemText>
        </MenuItem>
      </MenuWithPopup>

      {editDialog.isOpen && (
        <EditBrandDialog brandName={brandName} onClose={editDialog.onClose} onSave={onSave} />
      )}

      {deleteDialog.isOpen && (
        <DeleteBrandDialog
          handleClose={deleteDialog.onClose}
          brandName={brandName}
          handleDelete={onDelete}
        />
      )}
    </div>
  );
}
