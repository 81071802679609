import { MGPrisSchema, useMgPrisSchema } from '@dap-admin/schemas';
import { LocationMgPrisSchema, MgPris } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { EditCard, SaveOrCancelEdit } from '@dap-common/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { LabelValueTable, TextInputField } from '@shared/components';
import { pruneObject } from '@shared/utils';
import { FunctionComponent, useState } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';

interface Props {
  hasAdminRights: boolean;
  mgPris: MgPris;
  save: (mgPris: LocationMgPrisSchema) => void;
  saving: boolean;
}

const MgPrisCard: FunctionComponent<Props> = ({ mgPris, save, saving, hasAdminRights }) => {
  const t = useAppTFunction();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const form = useForm<MGPrisSchema>({
    defaultValues: useMgPrisSchema(mgPris).getDefault(),
    resolver: yupResolver(useMgPrisSchema(mgPris)),
  });
  const { handleSubmit, control, reset } = form;
  const departmentIdController = useController({ name: 'departmentId', control });
  const memberIdController = useController({ name: 'memberId', control });

  const onSubmit = handleSubmit((mgPris) => {
    save({ mgPrisData: pruneObject(mgPris, null) });
    setIsEditing(false);
  });

  const onCancel = () => {
    setIsEditing(false);
    reset();
  };

  return (
    <EditCard
      header={t('location.detail.mgPrice.name')}
      isEditing={isEditing}
      edit={() => setIsEditing(true)}
      cancel={onCancel}
      isEditable={hasAdminRights}
    >
      {isEditing ? (
        <FormProvider {...form}>
          <Stack spacing={2} pt={0.5}>
            <TextInputField
              {...memberIdController.field}
              error={memberIdController.fieldState.error?.message}
              label={t('location.detail.mgPrice.memberId')}
              fullWidth
            />

            <TextInputField
              {...departmentIdController.field}
              error={departmentIdController.fieldState.error?.message}
              label={t('location.detail.mgPrice.departmentId')}
              fullWidth
            />
            <SaveOrCancelEdit save={onSubmit} cancel={onCancel} align="right" loading={saving} />
          </Stack>
        </FormProvider>
      ) : (
        <LabelValueTable
          items={[
            {
              label: t('location.detail.mgPrice.memberId'),
              value: mgPris?.memberId,
            },
            {
              label: t('location.detail.mgPrice.departmentId'),
              value: mgPris?.departmentId,
            },
          ]}
        />
      )}
    </EditCard>
  );
};

export default MgPrisCard;
