import { messages } from '@dap-common/i18n';
import {
  BoxProps,
  Button,
  Card,
  CardContent,
  CardHeader,
  SxProps,
  Typography,
} from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends BoxProps {
  header: ReactNode;
  isEditing: boolean;
  edit: () => void;
  cancel: () => void;
  isEditable: boolean;
}

const EditCard: FunctionComponent<Props> = ({
  header,
  children,
  edit,
  isEditing,
  isEditable,
  cancel,
}) => {
  const { t } = useTranslation();

  return (
    <Card variant="outlined" color="primary" sx={cardSx} data-testid={`EditCard-${header}`}>
      <CardHeader
        title={
          <Typography variant="h6" noWrap>
            {header}
          </Typography>
        }
        sx={cardHeaderSx}
        action={
          !isEditing ? (
            <Button
              aria-label={t(messages.common.form.edit, { label: header })}
              onClick={edit}
              size="small"
              variant="text"
              color="inherit"
            >
              {t(messages.common.edit)}
            </Button>
          ) : (
            <Button
              aria-label={t(messages.common.form.cancel, { label: header })}
              onClick={cancel}
              size="small"
              variant="text"
              color="inherit"
            >
              {t(messages.common.cancel)}
            </Button>
          )
        }
      />
      <CardContent sx={cardContentSx}>{children}</CardContent>
    </Card>
  );
};

const cardSx: SxProps = {
  height: '100%',
};

const cardHeaderSx: SxProps = {
  '& .MuiCardHeader-content': {
    width: '100%',
    overflow: 'hidden',
  },
};

const cardContentSx: SxProps = {
  padding: 2,
  paddingTop: 1,
};

export default EditCard;
