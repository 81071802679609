import {
  LocationEditSchema,
  mapToPatchLocationDTO,
  useLocationEditSchema,
} from '@dap-admin/schemas';
import { AppBasic, Location, PatchLocation, RegionV2 } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexColumn, FlexRow, LabelValue } from '@dap-common/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import {
  Chip,
  ContainedButton,
  Dialog,
  DialogProps,
  TagHeading,
  UnderlinedTabs,
} from '@shared/components';
import { WarningIcon } from '@shared/custom-icons';
import { controlledElementA11yProps } from '@shared/utils';
import { isEqual } from 'lodash';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import EditAddress from './EditAddress';
import EditDetails from './EditDetails';
import EditServices from './EditServices';
import { useBrandTFunction } from '@dap-admin/i18n';

interface Props extends DialogProps {
  location: Location;
  availableRegions: Array<RegionV2>;
  availableServices: Array<AppBasic>;
  updateLocationDetails: (formData: PatchLocation) => void;
  isDataownerAdmin: boolean;
  isBrandAdmin: boolean;
}

const tabsId = 'edit-location-details-tabs';

const getDefaultValues = (
  location: Location,
  availableRegions: Array<RegionV2>
): LocationEditSchema => ({
  details: {
    glns: location.glns.map(({ id }) => ({ id: id })),
    contact: {
      email: location.contactInfo.contact.email,
      phone: location.contactInfo.contact.phone,
      homepage: location.contactInfo.contact.homepage,
    },
    name: location.name,
    regionKey: availableRegions.find((region) => region.name === location.regionName)?.key || '',
  },
  address: {
    addressAndPostalAddressEqual: isEqual(
      location.contactInfo.address,
      location.contactInfo.postalAddress
    ),
    address: {
      street: location.contactInfo.address.street,
      postalCode: location.contactInfo.address.postalCode,
      city: location.contactInfo.address.city,
      country: location.contactInfo.address.country,
    },
    postalAddress: {
      street: location.contactInfo.postalAddress?.street || '',
      postalCode: location.contactInfo.postalAddress?.postalCode || '',
      city: location.contactInfo.postalAddress?.city || '',
      country: location.contactInfo.postalAddress?.country || '',
    },
  },
  services: {
    apps: location.apps.map(({ key }) => key),
  },
  coordinates: {
    lat: location.contactInfo.coordinates.lat,
    long: location.contactInfo.coordinates.long,
  },
});

export default function EditLocationDialog({
  open,
  onClose,
  location,
  availableRegions,
  availableServices,
  updateLocationDetails,
  isDataownerAdmin,
  isBrandAdmin,
}: Props) {
  const { palette } = useTheme();
  const t = useAppTFunction();
  const tBrand = useBrandTFunction(location.dataowner.brand.key);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const defaultValues: LocationEditSchema = useMemo(
    () => getDefaultValues(location, availableRegions),
    [availableRegions, location]
  );

  const schemas = useLocationEditSchema(location.apps);

  const form = useForm<LocationEditSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
    resolver: yupResolver(schemas),
  });

  const isDetailsInvalid = useMemo(
    () => !!form.formState.errors.details,
    [form.formState.errors.details]
  );

  const isAddressInvalid = useMemo(
    () => !!form.formState.errors.address,
    [form.formState.errors.address]
  );

  const isServicesInvalid = useMemo(
    () => !!form.formState.errors.services,
    [form.formState.errors.services]
  );

  const tabs = useMemo(
    () => [
      {
        label: isDetailsInvalid ? (
          <FlexRow gap={1}>
            {t('location.details')}
            <WarningIcon color="warning" />
          </FlexRow>
        ) : (
          t('location.details')
        ),
      },
      {
        label: isAddressInvalid ? (
          <FlexRow gap={1}>
            {t('location.detail.address.title')}
            <WarningIcon color="warning" />
          </FlexRow>
        ) : (
          t('location.detail.address.title')
        ),
      },
      ...(isBrandAdmin
        ? [
            {
              label: isServicesInvalid ? (
                <FlexRow gap={1}>
                  {t('brandadmin.services.title')}
                  <WarningIcon color="warning" />
                </FlexRow>
              ) : (
                t('brandadmin.services.title')
              ),
            },
          ]
        : []),
    ],
    [isAddressInvalid, isDetailsInvalid, isServicesInvalid, isBrandAdmin, t]
  );

  const handleSave = (input: LocationEditSchema) => {
    updateLocationDetails(mapToPatchLocationDTO(input));
    onClose();
  };

  const hasPinmetoMapping = !!location.integrationDetails.pinmeto.pinmetoId;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      closeOnBackdropClick={false}
      PaperProps={{ sx: { height: '800px' } }}
    >
      <DialogTitle variant="h6" display="flex" flexDirection="column" gap={2}>
        <TagHeading alignItems="start">
          <FlexColumn>
            {location.name}
            <LabelValue
              label={t('location.id')}
              value={<Chip backgroundColor={palette.info.main} label={location.uuid} />}
              labelTypographyProps={{
                color: 'text.secondary',
                variant: 'body2',
                fontWeight: 400,
              }}
              sx={{ padding: '4px 0' }}
            />
          </FlexColumn>
        </TagHeading>
      </DialogTitle>

      <div>
        <UnderlinedTabs
          tabs={tabs}
          currentTab={currentTabIndex}
          id={tabsId}
          onChange={(_: React.SyntheticEvent, newValue: number) => setCurrentTabIndex(newValue)}
        />
      </div>

      <DialogContent role="tabpanel" {...controlledElementA11yProps(tabsId, currentTabIndex)}>
        <FormProvider {...form}>
          {currentTabIndex === 0 && (
            <EditDetails
              location={location}
              availableRegions={availableRegions}
              isDataownerAdmin={isDataownerAdmin}
            />
          )}
          {currentTabIndex === 1 && <EditAddress hasPinmetoMapping={hasPinmetoMapping} />}
          {currentTabIndex === 2 && isBrandAdmin && (
            <>
              <Alert severity="info" sx={{ my: 2 }}>
                {t('brandadmin.services.optionsHelp2', {
                  higherEntity: tBrand('brand.dataowner.name.singular'),
                  entity: tBrand('brand.location.name.definite'),
                })}
              </Alert>
              <EditServices availableServices={availableServices} />
            </>
          )}
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant="text" size="large" color="grey" onClick={onClose} disableElevation>
          {t('common.cancel')}
        </Button>
        <ContainedButton onClick={form.handleSubmit(handleSave)}>
          {t('common.save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
