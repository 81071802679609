import { LocationBasic, LocationProperty } from '@dap-admin/types';
import BaseTable from './BaseTable';
import { useBrandadminTFunction, useBrandTFunction } from '@dap-admin/i18n';
import { useNavigate } from 'react-router-dom';
import { getLocationRoute } from '@dap-common/consts';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { DataGridClass } from '@shared/styles';
import { ReactElement, useMemo } from 'react';
import { Chip, LimitedList } from '@shared/components';
import { useTheme } from '@mui/material';
import { FlexRow } from '@dap-common/ui';
import { FilterOperatorSelect } from './components';

interface Props {
  locations: Array<LocationBasic>;
  brandKey: string;
  customActions: ReactElement;
}

export default function DataownerLocationsTable({ locations, brandKey, customActions }: Props) {
  const t = useBrandadminTFunction();
  const tBrand = useBrandTFunction(brandKey);
  const navigate = useNavigate();
  const { palette } = useTheme();

  const onRowClick = (params: GridRowParams) => {
    navigate(getLocationRoute({ locationKey: params.row.key }));
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('location.myLocations.name'),
        minWidth: 400,
        cellClassName: () => DataGridClass.cellBold,
      },
      {
        field: 'regionName',
        headerName: t('location.region.name'),
        minWidth: 200,
      },
      {
        field: 'enabled',
        headerName: t('brandadmin.filter.status.title'),
        headerAlign: 'left',
        type: 'boolean',
        align: 'left',
        minWidth: 150,
        renderCell: ({ value: enabled }: GridRenderCellParams<boolean, LocationBasic>) =>
          enabled ? (
            <Chip label={t('brandadmin.filter.status.active')} />
          ) : (
            <Chip
              label={t('brandadmin.filter.status.deactivated')}
              backgroundColor={palette.warning.main}
            />
          ),
        filterOperators: [
          {
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField ||
                !filterItem.operatorValue ||
                filterItem.value === undefined // initially the filter value is set to undefined / user has not selected anything yet. If so, must return null to show all items
              ) {
                return null;
              }
              return ({ value }) => {
                // Convert value to boolean and check if it matches the filter criteria
                return Boolean(value) !== Boolean(filterItem.value);
              };
            },
            value: 'is',
            InputComponent: FilterOperatorSelect,
            InputComponentProps: { type: 'boolean' },
          },
        ],
      },
      {
        field: 'locationProperties',
        headerName: tBrand('brand.location.locationProperties.name.plural', 'capitalize'),
        width: 300,
        renderCell: ({
          value: locationProperties,
        }: GridRenderCellParams<LocationProperty[], LocationBasic>) =>
          locationProperties && (
            <LimitedList
              items={locationProperties.map(({ key, title }: LocationProperty) => (
                <Chip key={key} label={title} />
              ))}
              max={1}
              row
            />
          ),
      },
    ],
    [palette.warning.main, t, tBrand]
  );

  return (
    <BaseTable
      autoHeight
      columns={columns}
      rows={locations}
      localeText={{
        noRowsLabel: t('location.noLocations', {
          locations: tBrand('brand.location.name.plural'),
        }),
      }}
      getRowId={(row) => row.uuid}
      onRowClick={onRowClick}
      getRowClassName={() => DataGridClass.cellClickable}
      components={{
        Toolbar: (props) => CustomGridToolbar(props, customActions),
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'enabled', sort: 'desc' }],
        },
      }}
    />
  );
}

const CustomGridToolbar = (props: GridToolbarProps, customActions?: ReactElement) => {
  return (
    <GridToolbarContainer {...props}>
      <FlexRow justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
        <FlexRow gap={1}>
          <GridToolbarQuickFilter data-testid="EmployeeTableSearch" />
          <GridToolbarColumnsButton
            data-testid="EmployeeTableColumns"
            placeholder={''}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
          <GridToolbarFilterButton
            data-testid="EmployeeTableFilters"
            placeholder={''}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
          <GridToolbarDensitySelector
            data-testid="EmployeeTableDensity"
            placeholder={''}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        </FlexRow>
        <FlexRow gap={1}>{customActions}</FlexRow>
      </FlexRow>
    </GridToolbarContainer>
  );
};
