import { useBrandTFunction } from '@dap-admin/i18n';
import { Location } from '@dap-admin/types';
import { getDataownerRoute } from '@dap-common/consts';
import { brandStylesConfig } from '@dap-common/styles';
import { RecognizedBrands } from '@dap-common/types';
import { FlexRow } from '@dap-common/ui';
import { Typography } from '@mui/material';
import { Paper } from '@shared/components';
import { EntityButton, EntityChart } from '../Chart';

interface Props {
  location: Location;
}

export default function LocationChart({ location }: Props) {
  const t = useBrandTFunction(location.dataowner.brand.key);
  const brandStyle =
    brandStylesConfig[location.dataowner.brand.key as RecognizedBrands] ||
    brandStylesConfig.default;

  return (
    <Paper sx={{ height: '100%' }}>
      <FlexRow
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'nowrap'}
        gap={3}
        mb={3}
      >
        <Typography variant="h6" noWrap>
          {t('brand.location.name.singular', 'capitalize')}
        </Typography>
        {brandStyle.icon && (
          <img
            src={brandStyle.icon}
            alt={`${location.dataowner.brand.name} logo`}
            height="24px"
            width="24px"
            style={{ objectFit: 'contain' }}
          />
        )}
      </FlexRow>

      <EntityChart>
        <EntityButton
          to={getDataownerRoute({ dataownerKey: location.dataowner.key })}
          hoverColor="primary"
        >
          <Typography component="span" fontWeight="bold" noWrap>
            {location.dataowner.name}
          </Typography>
          <Typography component="span" variant="body2" color="text.secondary" noWrap>
            {t('brand.dataowner.name.singular', 'capitalize')}
          </Typography>
        </EntityButton>

        <EntityButton active color="secondary">
          <Typography fontWeight="bold" noWrap>
            {location.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" noWrap>
            {t('brand.location.name.singular', 'capitalize')}
          </Typography>
        </EntityButton>
      </EntityChart>
    </Paper>
  );
}
