import { ListItem, IconButton } from '@mui/material';
import { TrashIcon } from '@sanity/icons';
import { PropsWithChildren, useMemo } from 'react';

interface Props {
  removeButtonLabel: string;
  onRemove: () => void;
  inputSize: 'small' | 'medium';
  inputMargin: 'none' | 'dense' | 'normal';
}

/**
 * FieldArrayItem is to be used as the child of FieldArray component
 * @param inputSize - Must be set equal to the size of child input field
 * @param inputMargin - Must be set equal to the margin of child input field
 */

export default function FieldArrayItem({
  removeButtonLabel,
  onRemove,
  children,
  inputSize,
  inputMargin,
}: PropsWithChildren<Props>) {
  const marginTop = useMemo(() => getMarginTop(inputSize, inputMargin), [inputMargin, inputSize]);

  return (
    <ListItem
      disableGutters
      disablePadding
      sx={{ alignItems: 'flex-start', ':not(:last-of-type)': { mb: 1 } }}
    >
      {children}
      <IconButton
        aria-label={removeButtonLabel}
        onClick={() => onRemove()}
        size={inputSize === 'small' ? 'medium' : 'large'}
        sx={{ mt: marginTop }}
      >
        <TrashIcon />
      </IconButton>
    </ListItem>
  );
}

const getMarginTop = (inputSize: Props['inputSize'], inputMargin: Props['inputMargin']) => {
  return () => {
    if (inputSize === 'small') {
      switch (inputMargin) {
        case 'none':
          return 0;
        case 'dense':
          return 1;
        case 'normal':
          return 2;
        default:
          return 0.5;
      }
    }

    switch (inputMargin) {
      case 'none':
        return 0.25;
      case 'dense':
        return 1.25;
      case 'normal':
        return 2.25;
      default:
        return 0.5;
    }
  };
};
