import { useBrandadminTFunction, useBrandTFunction } from '@dap-admin/i18n';
import { Dataowner } from '@dap-admin/types';
import { FormDialog } from '@dap-common/ui';

interface Props {
  dataowner: Dataowner;
  cancelDialog: () => void;
  deleteDataowner: (dataownerKey: string) => void;
  loading: boolean;
  brandKey: string;
}

const DeleteDataowner = ({
  dataowner,
  cancelDialog,
  deleteDataowner,
  loading,
  brandKey,
}: Props) => {
  const t = useBrandadminTFunction();
  const tBrand = useBrandTFunction(dataowner.brand.key);

  return (
    <FormDialog
      title={t('dataowner.actions.delete', {
        dataowner: tBrand('brand.dataowner.name.singular'),
      })}
      entityName={dataowner.name}
      content={t('dataowner.delete.description', {
        dataownerName: dataowner.name,
        theDataowner: tBrand('brand.dataowner.name.definite'),
      })}
      handleConfirm={() => deleteDataowner(dataowner.key)}
      handleCancel={() => cancelDialog()}
      loading={loading}
    />
  );
};

export default DeleteDataowner;
