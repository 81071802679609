import { useBrandTFunction } from '@dap-admin/i18n';
import { BrandV2, Dataowner, Location, PatchLocation } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { MenuWithPopup } from '@dap-common/ui';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { ProhibitEntityIcon, TrashIcon, UnlockEntityIcon } from '@shared/custom-icons';
import { useDisclosure } from '@shared/hooks';
import { useEffect, useState } from 'react';
import DeleteLocation from './DeleteLocation';
import DisableEnableLocation from './DisableEnableLocation';
import EditLocationDialog from './EditLocationDetails/EditLocationDialog';
import EditLocationPropertiesDialog from './LocationProperties/EditLocationPropertiesDialog';

interface Props {
  location: Location;
  actions: LocationDetailsMenuActions;
  isDataownerAdmin: boolean;
  isBrandAdmin: boolean;
  isSuperuser: boolean;
  availableRegions: BrandV2['regions'];
  availableServices: Dataowner['apps'];
  availableLocationProperties: BrandV2['locationProperties'];
}

export interface LocationDetailsMenuActions {
  onEdit: (formData: PatchLocation) => void;
  isUpdating: boolean;
  onDelete: () => void;
  isDeleting: boolean;
}

export default function LocationDetailsBlockActionMenu({
  actions,
  location,
  isDataownerAdmin,
  isBrandAdmin,
  isSuperuser,
  availableRegions,
  availableServices,
  availableLocationProperties,
}: Props) {
  const tApp = useAppTFunction();
  const tBrand = useBrandTFunction(location.dataowner.brand.key);

  const editDetailsDialog = useDisclosure();
  const editLocationPropertiesDialog = useDisclosure();
  const enableDisableDialog = useDisclosure();
  const deleteDialog = useDisclosure();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    if (
      editDetailsDialog.isOpen ||
      editLocationPropertiesDialog.isOpen ||
      enableDisableDialog.isOpen ||
      deleteDialog.isOpen
    ) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  }, [
    editDetailsDialog.isOpen,
    editLocationPropertiesDialog.isOpen,
    enableDisableDialog.isOpen,
    deleteDialog.isOpen,
  ]);

  return (
    <div>
      <MenuWithPopup isOpen={isMenuOpen}>
        <MenuItem onClick={editDetailsDialog.onOpen}>
          <ListItemIcon>
            <EditRoundedIcon fontSize={'small'} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
            {tApp('location.actionMenu.editDetails')}
          </ListItemText>
        </MenuItem>

        {isDataownerAdmin && (
          <MenuItem onClick={editLocationPropertiesDialog.onOpen}>
            <ListItemIcon>
              <EditRoundedIcon fontSize={'small'} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
              {`${tApp('common.edit')} ${tBrand('brand.location.locationProperties.name.plural')}`}
            </ListItemText>
          </MenuItem>
        )}

        {isDataownerAdmin && (
          <MenuItem onClick={enableDisableDialog.onOpen}>
            <ListItemIcon>
              {location.enabled ? (
                <ProhibitEntityIcon fontSize="small" />
              ) : (
                <UnlockEntityIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
              {location.enabled
                ? tApp('location.actionMenu.deactivate', {
                    location: tBrand('brand.location.name.singular'),
                  })
                : tApp('location.actionMenu.activate', {
                    location: tBrand('brand.location.name.singular'),
                  })}
            </ListItemText>
          </MenuItem>
        )}

        {isSuperuser && (
          <MenuItem onClick={deleteDialog.onOpen}>
            <ListItemIcon>
              <TrashIcon fontSize={'small'} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
              {tApp('location.actionMenu.delete', {
                location: tBrand('brand.location.name.singular'),
              })}
            </ListItemText>
          </MenuItem>
        )}
      </MenuWithPopup>

      {editDetailsDialog.isOpen && (
        <EditLocationDialog
          onClose={editDetailsDialog.onClose}
          open={editDetailsDialog.isOpen}
          location={location}
          availableRegions={availableRegions}
          availableServices={availableServices}
          updateLocationDetails={(input) => {
            actions.onEdit(input);
            editDetailsDialog.onClose();
          }}
          isDataownerAdmin={isDataownerAdmin}
          isBrandAdmin={isBrandAdmin}
        />
      )}

      {isDataownerAdmin && editLocationPropertiesDialog.isOpen && (
        <EditLocationPropertiesDialog
          open={editLocationPropertiesDialog.isOpen}
          locationProperties={location.locationProperties || []}
          availableLocationProperties={availableLocationProperties || []}
          brandKey={location.dataowner.brand.key}
          save={(input) => {
            actions.onEdit(input);
            editLocationPropertiesDialog.onClose();
          }}
          saving={actions.isUpdating}
          close={editLocationPropertiesDialog.onClose}
        />
      )}

      {isDataownerAdmin && enableDisableDialog.isOpen && (
        <DisableEnableLocation
          open={enableDisableDialog.isOpen}
          enabled={location.enabled}
          name={location.name}
          brandKey={location.dataowner.brand.key}
          cancelEdit={enableDisableDialog.onClose}
          updateLocation={(input) => {
            actions.onEdit(input);
            enableDisableDialog.onClose();
          }}
          isUpdatingLocation={actions.isUpdating}
        />
      )}

      {isSuperuser && deleteDialog.isOpen && (
        <DeleteLocation
          name={location.name}
          close={deleteDialog.onClose}
          deleteLocation={actions.onDelete}
          isDeletingLocation={actions.isDeleting}
          brandKey={location.dataowner.brand.key}
        />
      )}
    </div>
  );
}
