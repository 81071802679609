import { dapBffApi } from '@dap-bff/data-access';
import { dapApi } from '@dap-dashboard/data-access';
import { sanityApi } from '@dap-sanity/data-access';
import {
  Action,
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
  Store,
} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { reportReduxActionException } from '@shared/reporting';
import authReducer from '@shared/state/auth';
import { combineEpics, createEpicMiddleware, Epic, StateObservable } from 'redux-observable';
import { catchError, Observable } from 'rxjs';
import { brandadminApi } from '../api/brandadminApi';
import brandReducer from './brand/brandReducer';
import dialogReducer from './dialog/dialogReducer';
import locationEpic from './location/locationEpic';
import locationReducer from './location/locationReducer';
import snackbarReducer from './snackbar/snackbarReducer';
import userReducer from './user/userReducer';
import { brregApi } from '../api/brregApi';
import { datahubApi } from '@dap-datahub/data-access';

export const epicMiddleware = createEpicMiddleware();

export const rootEpic: Epic = (
  action$: Observable<Action>,
  store$: StateObservable<void>,
  dependencies: any
) =>
  combineEpics(locationEpic)(action$, store$, dependencies).pipe(
    catchError((error: any, source: any) => {
      console.error(error);
      return source;
    })
  );

// Sentry docs: to apply Sentry to redux, we have to initialize createReduxEnhancer at the same place where we initialize redux store.
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const rtkQueryErrorReporterMiddleware: Middleware = (api) => (next) => (action) => {
  try {
    return next(action); // dispatch
  } catch (err: any) {
    reportReduxActionException(action, err);
    throw err; // re-throw error
  }
};

const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    reportReduxActionException(action, new Error('Rejected action'));
  }

  return next(action);
};

const rootReducer = combineReducers({
  auth: authReducer,
  brand: brandReducer,
  dialog: dialogReducer,
  location: locationReducer,
  user: userReducer,
  snackbar: snackbarReducer,
  [sanityApi.reducerPath]: sanityApi.reducer,
  [dapBffApi.reducerPath]: dapBffApi.reducer,
  [dapApi.reducerPath]: dapApi.reducer,
  [brandadminApi.reducerPath]: brandadminApi.reducer,
  [brregApi.reducerPath]: brregApi.reducer,
  [datahubApi.reducerPath]: datahubApi.reducer,
});

export const store: Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      epicMiddleware,
      sanityApi.middleware,
      dapBffApi.middleware,
      dapApi.middleware,
      brandadminApi.middleware,
      brregApi.middleware,
      datahubApi.middleware,
      rtkQueryErrorReporterMiddleware,
      rtkQueryErrorLogger,
    ]),
  enhancers: [sentryReduxEnhancer],
  devTools: process.env['NX_PUBLIC_REACT_APP_ENV'] !== 'prod',
});

epicMiddleware.run(rootEpic);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
