import { useBrandTFunction } from '@dap-admin/i18n';
import { Dataowner } from '@dap-admin/types';
import { getOrgNumberNameFromCountry } from '@dap-admin/utils';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexColumn, FlexRow, InputDatePicker } from '@dap-common/ui';
import { Checkbox, Stack, Typography } from '@mui/material';
import { FieldArray, FieldArrayItem, SelectInputField, TextInputField } from '@shared/components';
import { CountryCode } from '@shared/types';
import { capitalizeFirstLetter } from '@shared/utils';
import { Controller, useController, useFieldArray, useFormContext } from 'react-hook-form';
import { EditDataownerSchema } from '../editDataownerDetailsSchema';

interface Props {
  dataowner: Dataowner;
  canEditMembership: boolean;
  canEditGln: boolean;
}

export default function DataownerDetailsForm({ dataowner, canEditMembership, canEditGln }: Props) {
  const t = useAppTFunction();
  const tBrand = useBrandTFunction(dataowner.brand.key);

  const { control, watch } = useFormContext<EditDataownerSchema>();
  const nameController = useController({ name: 'details.name', control });
  const orgNrController = useController({ name: 'details.orgNr', control });
  const memberFromController = useController({ name: 'details.memberFrom', control });
  const memberToController = useController({ name: 'details.memberTo', control });
  const notMemberController = useController({ name: 'details.notMember', control });
  const countryController = useController({ name: 'details.country', control });
  const glnsController = useController({ name: 'details.glns', control });

  const country = watch('details.country');
  const notMember = watch('details.notMember');

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'details.glns',
  });

  return (
    <div>
      <TextInputField
        {...nameController.field}
        error={nameController.fieldState.error?.message}
        label={t('pagesHomeDataowner.form.name')}
        required
        fullWidth
        margin="normal"
      />

      <SelectInputField
        {...countryController.field}
        error={countryController.fieldState.error?.message}
        placeholder={t('common.country.title')}
        label={t('common.country.title')}
        menuItems={[
          { value: CountryCode.Norway, name: t('common.countries.NO') },
          { value: CountryCode.Sweden, name: t('common.countries.SE') },
          { value: CountryCode.Finland, name: t('common.countries.FI') },
        ]}
        required
        margin="normal"
        fullWidth
      />

      <TextInputField
        {...orgNrController.field}
        error={orgNrController.fieldState.error?.message}
        label={getOrgNumberNameFromCountry(country, t)}
        required
        fullWidth
        margin="normal"
      />

      {canEditMembership && (
        <FlexColumn width={'fit-content'} gap={0.5} mt={2} mb={1}>
          <FlexRow>
            <Typography component={'label'} fontWeight={700}>
              {t('dataowner.membership.nonMember')}
            </Typography>
            <Checkbox {...notMemberController.field} checked={notMemberController.field.value} />
          </FlexRow>
        </FlexColumn>
      )}

      {!notMember && (
        <Stack direction="row" gap={3}>
          <Stack flex={'50%'}>
            <InputDatePicker
              {...memberFromController.field}
              error={memberFromController.fieldState.error?.message}
              required
              label={capitalizeFirstLetter(
                t('pagesHomeDataowner.actionMenu.memberFrom', {
                  memberType: tBrand('brand.dataowner.name.singular'),
                })
              )}
              margin="dense"
            />
          </Stack>
          <Stack flex={'50%'}>
            <InputDatePicker
              {...memberToController.field}
              error={memberToController.fieldState.error?.message}
              label={capitalizeFirstLetter(
                t('pagesHomeDataowner.actionMenu.memberTo', {
                  memberType: tBrand('brand.dataowner.name.singular'),
                })
              )}
              margin="dense"
            />
          </Stack>
        </Stack>
      )}

      {canEditGln && (
        <FieldArray
          onAdd={() => {
            append({ id: '' });
          }}
          label={t('location.detail.gln.title')}
          addButtonLabel={t('common.add')}
          name={glnsController.field.name}
          margin="normal"
          fullWidth
          error={glnsController.fieldState.error?.message}
        >
          {fields.map((field, index) => (
            <FieldArrayItem
              key={field.id}
              onRemove={() => remove(index)}
              removeButtonLabel={t('common.remove')}
              inputSize="medium"
              inputMargin="none"
            >
              <Controller
                name={`details.glns.${index}.id`}
                control={control}
                render={(props) => (
                  <TextInputField
                    label={''}
                    {...props.field}
                    size="medium"
                    error={props.fieldState.error?.message}
                    margin="none"
                    fullWidth
                  />
                )}
              />
            </FieldArrayItem>
          ))}
        </FieldArray>
      )}
    </div>
  );
}
