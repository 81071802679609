import { AppBasic, AppDetailed } from '@dap-admin/types';
import { useServiceFilters } from '@dap-admin/utils';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexRow, useCheckboxGroup } from '@dap-common/ui';
import { Checkbox, FormControl, FormGroup, List, Stack, Typography } from '@mui/material';
import { InfoTooltip } from '@shared/components';
import { useController, useFormContext } from 'react-hook-form';
import AppOutlinedListItem from '../../List/AppOutlinedListItem';
import { EditDataownerSchema } from '../editDataownerDetailsSchema';

interface Props {
  selectedApps: Array<AppBasic>;
  availableApps: Array<AppDetailed>;
}

export default function DataownerServicesForm({ selectedApps, availableApps }: Props) {
  const t = useAppTFunction();

  const { control } = useFormContext<EditDataownerSchema>();

  const appsController = useController({ name: 'services.apps', control });

  const { defaultServices, filteredServices } = useServiceFilters(availableApps);

  const { handleChange } = useCheckboxGroup({
    onChange: appsController.field.onChange,
    value: appsController.field.value,
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
    handleChange(event, checked, availableApps, false);

  return (
    <div>
      <FormControl component={'fieldset'} fullWidth>
        <FormGroup>
          <Stack mt={2} mb={4}>
            <Typography variant="body1" fontWeight={700}>
              {t('brandadmin.services.title')}
            </Typography>
            {filteredServices.length > 0 ? (
              <List>
                {filteredServices.map((service) => (
                  <AppOutlinedListItem
                    key={service.key}
                    app={service}
                    secondaryAction={
                      <Checkbox
                        edge={'end'}
                        checked={appsController.field.value.includes(service.key)}
                        value={service.key}
                        onChange={handleCheckboxChange}
                        onBlur={appsController.field.onBlur}
                      />
                    }
                  />
                ))}
              </List>
            ) : (
              <Typography>{t('brandadmin.services.empty')}</Typography>
            )}
          </Stack>

          <Stack>
            <FlexRow alignItems={'center'}>
              <Typography variant="body1" fontWeight={700}>
                {t('location.detail.services.default.title')}
              </Typography>
              <InfoTooltip tooltipText={t('location.detail.services.default.description')} />
            </FlexRow>
            {defaultServices.length > 0 ? (
              <List>
                {defaultServices.map((service) => (
                  <AppOutlinedListItem
                    key={service.key}
                    app={service}
                    secondaryAction={
                      <Checkbox
                        edge={'end'}
                        checked={appsController.field.value.includes(service.key)}
                        value={service.key}
                        onChange={handleCheckboxChange}
                        onBlur={appsController.field.onBlur}
                      />
                    }
                  />
                ))}
              </List>
            ) : (
              <Typography>{t('brandadmin.services.empty')}</Typography>
            )}
          </Stack>
        </FormGroup>
      </FormControl>
    </div>
  );
}
