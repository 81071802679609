import { AppBasic, AppDetailed, GLN } from '@dap-admin/types';
import { getIfDefaultApp } from '@dap-admin/utils';
import { AppTFunction } from '@dap-common/i18n';
import { Validations } from '@dap-common/utils';
import { CountryCode } from '@shared/types';
import * as yup from 'yup';

const name = (tApp: AppTFunction) => {
  return {
    name: yup
      .string()
      .default('')
      .min(3, tApp('validation.characters.minimum', { count: 3 }))
      .matches(/[a-zA-ZÀ-ÿ&\s]/g, tApp('validation.letters.minimum', { count: 3 })),
  };
};

const apps = (
  availableApps: Array<Pick<AppBasic | AppDetailed, 'key' | 'name' | 'default' | 'uuid'>>
) => ({
  apps: yup
    .array(
      yup.object({
        key: yup.string().required(),
        name: yup.string().required(),
        default: yup.boolean().required(),
        uuid: yup.string().nullable().default(null),
      })
    )
    .default(
      availableApps.map((app) => ({
        key: app.key,
        name: app.name,
        default: getIfDefaultApp(app),
        uuid: app.uuid,
      }))
    ),
});

const country = () => ({
  country: yup.string().default(CountryCode.Norway),
});

const glns = (tApp: AppTFunction, glns?: Array<GLN>) => {
  const schema = {
    glns: yup
      .array(
        yup.object({
          id: Validations.numberString(tApp)
            .required(tApp('validation.required'))
            .length(13, tApp('validation.gln.format'))
            .default(''),
        })
      )
      .default(glns ? glns.map(({ id }) => ({ id })) : []),
  };
  return schema;
};

const address = (isRequired: boolean, tApp: AppTFunction) => {
  const schema = {
    ...country(),
    street: yup
      .string()
      .default('')
      .test('isRequired', tApp('validation.required'), (value) => {
        if (isRequired && !value) {
          return false;
        }
        return true;
      }),
    postalCode: yup
      .string()
      .test('isRequired', tApp('validation.required'), (value) => {
        if (isRequired && !value) {
          return false;
        }
        return true;
      })
      .concat(Validations.postalCode('country', tApp))
      .default(''),
    city: yup
      .string()
      .default('')
      .test('isRequired', tApp('validation.required'), (value) => {
        if (isRequired && !value) {
          return false;
        }
        return true;
      }),
  };

  return schema;
};

export type NameSchema = ReturnType<typeof name>;
export type AppsSchema = ReturnType<typeof apps>;
export type CountrySchema = ReturnType<typeof country>;
export type GLNSSchema = ReturnType<typeof glns>;
export type AddressSchema = ReturnType<typeof address>;

const yupSchema = {
  name,
  apps,
  country,
  glns,
  address,
};

export default yupSchema;
