import { AppDetailed, Dataowner, UpdateDataownerSchema } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { FlexColumn, FlexRow, LabelValue } from '@dap-common/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { Chip, ContainedButton, Dialog, TagHeading, UnderlinedTabs } from '@shared/components';
import { WarningIcon } from '@shared/custom-icons';
import { Country } from '@shared/types';
import { controlledElementA11yProps } from '@shared/utils';
import { formatISO } from 'date-fns';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import DataownerDetailsForm from './EditDataownerDetails/DataownerDetailsForm';
import DataownerServicesForm from './EditDataownerDetails/DataownerServicesForm';
import { EditDataownerSchema, useEditDataownerSchema } from './editDataownerDetailsSchema';
import { useBrandTFunction } from '@dap-admin/i18n';

interface Props {
  open: boolean;
  dataowner: Dataowner;
  cancelEdit: () => void;
  updateDataowner: (input: UpdateDataownerSchema) => void;
  isUpdatingDataowner: boolean;
  isBrandadmin: boolean;
  brandApps: Array<AppDetailed>;
}

const tabsId = 'edit-dataowner-details-tabs';

const EditDataownerDetails = ({
  open,
  dataowner,
  cancelEdit,
  brandApps,
  updateDataowner,
  isUpdatingDataowner,
  isBrandadmin,
}: Props) => {
  const t = useAppTFunction();
  const tBrand = useBrandTFunction(dataowner.brand.key);
  const { palette } = useTheme();
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const form = useForm<EditDataownerSchema>({
    defaultValues: useEditDataownerSchema(dataowner).getDefault(),
    resolver: yupResolver(useEditDataownerSchema(dataowner)),
  });

  const handleSave = (formData: EditDataownerSchema) => {
    const data = {
      name: formData.details.name,
      country: formData.details.country as Country,
      orgNr: formData.details.orgNr,
      memberFrom:
        formData.details.memberFrom && !formData.details.notMember
          ? formatISO(formData.details.memberFrom, { representation: 'date' })
          : undefined,
      memberTo:
        formData.details.memberTo && !formData.details.notMember
          ? formatISO(formData.details.memberTo, { representation: 'date' })
          : undefined,
      glns: formData.details.glns.map((gln: { id: string }) => gln.id),
      apps: formData.services.apps,
    };

    updateDataowner(data);
  };

  const isDetailsInvalid = useMemo(
    () => !!form.formState.errors.details,
    [form.formState.errors.details]
  );

  const isServicesInvalid = useMemo(
    () => !!form.formState.errors.services,
    [form.formState.errors.services]
  );

  const tabs = useMemo(
    () => [
      {
        label: (
          <FlexRow gap={1}>
            {t('location.details')}
            {isDetailsInvalid && <WarningIcon color="warning" />}
          </FlexRow>
        ),
      },
      ...(isBrandadmin
        ? [
            {
              label: (
                <FlexRow gap={1}>
                  {t('brandadmin.services.title')}
                  {isServicesInvalid && <WarningIcon color="warning" />}
                </FlexRow>
              ),
            },
          ]
        : []),
    ],
    [isDetailsInvalid, t, isBrandadmin, isServicesInvalid]
  );

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={cancelEdit}
      fullWidth
      closeOnBackdropClick={false}
      PaperProps={{ sx: { height: '800px' } }}
    >
      <DialogTitle>
        <TagHeading color="primary" alignItems="start">
          <FlexColumn>
            {dataowner.name}
            <LabelValue
              label={t('location.id')}
              value={<Chip backgroundColor={palette.info.main} label={dataowner.key} />}
              labelTypographyProps={{
                color: 'text.secondary',
                variant: 'body2',
                fontWeight: 400,
              }}
              sx={{ padding: '4px 0' }}
            />
          </FlexColumn>
        </TagHeading>
      </DialogTitle>

      {isBrandadmin && (
        <div>
          <UnderlinedTabs
            tabs={tabs}
            currentTab={currentTabIndex}
            id={tabsId}
            onChange={(_: React.SyntheticEvent, newValue: number) => setCurrentTabIndex(newValue)}
          />
        </div>
      )}

      <DialogContent role="tabpanel" {...controlledElementA11yProps(tabsId, currentTabIndex)}>
        <FormProvider {...form}>
          {currentTabIndex === 0 && (
            <DataownerDetailsForm
              dataowner={dataowner}
              canEditMembership={isBrandadmin}
              canEditGln={isBrandadmin}
            />
          )}
          {currentTabIndex === 1 && isBrandadmin && (
            <>
              <Alert severity="info" sx={{ my: 2 }}>
                {t('brandadmin.services.optionsHelp2', {
                  higherEntity: t('brandadmin.brand.title.definite'),
                  entity: tBrand('brand.dataowner.name.singular'),
                })}
              </Alert>
              <DataownerServicesForm selectedApps={dataowner.apps} availableApps={brandApps} />
            </>
          )}
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant="text" size="large" color="grey" onClick={cancelEdit} disableElevation>
          {t('common.cancel')}
        </Button>
        <ContainedButton loading={isUpdatingDataowner} onClick={form.handleSubmit(handleSave)}>
          {t('common.save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditDataownerDetails;
