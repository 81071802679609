import {
  LocationPinMeToMappingSchema,
  Location,
  MutationResult,
  UpdatePinMeToMappingRequest,
} from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { Buttons, CheckboxWithLabel, UniversalFormProvider } from '@dap-common/ui';
import { PinmetoLocationMappingDTO } from '@generated-types';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, Dialog, TextInputField } from '@shared/components';
import { pruneObject } from '@shared/utils';
import { FunctionComponent, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import * as yup from 'yup';

export type PinmetoAction = 'add' | 'change' | 'delete' | 'none';

interface Props {
  close: (syncSuccess: boolean) => void;
  action: PinmetoAction;
  savePinmetoData: (input: LocationPinMeToMappingSchema) => void;
  confirmDelete: () => void;
  pinmetoId?: string;
  hasAutomatedSync: boolean;
  updatePinMeToState: MutationResult<PinmetoLocationMappingDTO, UpdatePinMeToMappingRequest>;
  removePinMeToState: MutationResult<void, Location['key']>;
}

const formId = 'pin-me-to-form';

const PinmetoDialog: FunctionComponent<Props> = ({
  close,
  action,
  confirmDelete,
  savePinmetoData,
  updatePinMeToState,
  removePinMeToState,
  pinmetoId = '',
  hasAutomatedSync,
}) => {
  const newPinmetoConnection = !pinmetoId;
  const t = useAppTFunction();
  const open = action !== 'none';

  const pinmetoSchema = useMemo(
    () =>
      yup.object().shape({
        automatedSync: yup.boolean(),
        pinMeToId: yup.string().required(t('validation.required')),
      }),
    [t]
  );

  return action !== 'none' ? (
    <Dialog open={open} onClose={() => close(false)} maxWidth="xs" fullWidth>
      <DialogTitle>
        {action === 'add'
          ? t('location.detail.pinmeto.dialog.add.title')
          : action === 'delete'
          ? t('location.detail.pinmeto.dialog.delete.title')
          : t('location.detail.pinmeto.dialog.change.title')}
      </DialogTitle>
      {action !== 'delete' && (
        <DialogContent>
          <UniversalFormProvider
            schema={pinmetoSchema}
            defaultValues={{
              pinMeToId: pinmetoId,
              automatedSync: newPinmetoConnection || hasAutomatedSync,
            }}
          >
            <PinmetoForm
              savePinmetoData={savePinmetoData}
              newPinmetoConnection={newPinmetoConnection}
              formId={formId}
            />
          </UniversalFormProvider>
        </DialogContent>
      )}
      <DialogActions>
        <Buttons>
          <Button variant="text" color="inherit" size="large" onClick={() => close(false)}>
            {t('common.cancel')}
          </Button>
          {action === 'delete' ? (
            <ContainedButton
              onClick={confirmDelete}
              loading={removePinMeToState.isLoading}
              color="warning"
            >
              {t('location.detail.pinmeto.dialog.confirmYes')}
            </ContainedButton>
          ) : (
            <ContainedButton form={formId} type="submit" loading={updatePinMeToState.isLoading}>
              {t('location.detail.pinmeto.saveAndSync')}
            </ContainedButton>
          )}
        </Buttons>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default PinmetoDialog;

interface FormProps {
  savePinmetoData: (data: LocationPinMeToMappingSchema) => void;
  newPinmetoConnection: boolean;
  formId: string;
}

const PinmetoForm = ({ savePinmetoData, newPinmetoConnection, formId }: FormProps) => {
  const t = useAppTFunction();
  const { handleSubmit, control } = useFormContext<LocationPinMeToMappingSchema>();
  const onSubmit = (input: LocationPinMeToMappingSchema) => {
    const data = pruneObject(input);
    savePinmetoData(data);
  };
  const automatedSyncController = useController({
    name: 'automatedSync',
    control,
  });
  const pinMeToIdController = useController({
    name: 'pinMeToId',
    control,
  });

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)} noValidate>
      <TextInputField
        label={t('location.detail.pinmeto.dialog.change.label')}
        {...pinMeToIdController.field}
        required
        error={pinMeToIdController.fieldState.error?.message}
        margin="none"
        fullWidth
      />

      {newPinmetoConnection && (
        <CheckboxWithLabel
          label={t('location.detail.pinmeto.turnOnSync')}
          {...automatedSyncController.field}
          fullWidth
          margin="dense"
          size="medium"
        />
      )}
    </form>
  );
};
