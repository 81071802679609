import { EcommerceSchema, useEcommerceSchema } from '@dap-admin/schemas';
import { EcomAssortment, Ecommerce, LocationEcommerceSchema } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { CheckboxWithLabel, EditCard, SaveOrCancelEdit } from '@dap-common/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { LabelValueTable, SelectInputField, TextInputField } from '@shared/components';
import { pruneObject } from '@shared/utils';
import { FunctionComponent, useMemo, useState } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';

interface Props {
  ecommerce: Ecommerce;
  selectedEcommerceAssortments: EcomAssortment[];
  showOmniumData: boolean;
  publiclyAvailable: boolean;
  save: (updatedEcommerce: LocationEcommerceSchema) => void;
  saving: boolean;
  hasAdminRights: boolean;
}

const EcommerceCard: FunctionComponent<Props> = ({
  ecommerce,
  selectedEcommerceAssortments,
  showOmniumData,
  publiclyAvailable,
  save,
  saving,
  hasAdminRights,
}) => {
  const t = useAppTFunction();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const form = useForm<EcommerceSchema>({
    defaultValues: useEcommerceSchema(ecommerce).getDefault(),
    resolver: yupResolver(useEcommerceSchema(ecommerce)),
  });

  const { handleSubmit, control, reset } = form;

  const ecomAssortmentKeyController = useController({ name: 'ecomAssortmentKey', control });
  const papirflyStoreIdController = useController({ name: 'papirflyStoreId', control });
  const hasInventoryManagementController = useController({
    name: 'hasInventoryManagement',
    control,
  });

  const onSubmit = handleSubmit((updatedEcom) => {
    const body: LocationEcommerceSchema['ecommerce'] = {
      ecomAssortmentKey: updatedEcom.ecomAssortmentKey,
      hasInventoryManagement: updatedEcom.hasInventoryManagement,
      papirflyStoreId: updatedEcom.papirflyStoreId,
    };
    save({ ecommerce: pruneObject(body, null) });
    setIsEditing(false);
  });

  const assortmentOptions = useMemo(
    () =>
      selectedEcommerceAssortments.map(({ key, title }) => ({
        value: key,
        name: title,
      })),
    [selectedEcommerceAssortments]
  );

  const onCancel = () => {
    setIsEditing(false);
    reset();
  };

  return (
    <EditCard
      header={t('location.detail.ecommerce.name')}
      isEditing={isEditing}
      edit={() => setIsEditing(true)}
      cancel={onCancel}
      isEditable={hasAdminRights}
    >
      {isEditing ? (
        <FormProvider {...form}>
          <Stack spacing={2} pt={0.5}>
            <SelectInputField
              {...ecomAssortmentKeyController.field}
              error={ecomAssortmentKeyController.fieldState.error?.message}
              label={t('location.detail.ecommerce.assortment')}
              menuItems={assortmentOptions}
              margin="normal"
              fullWidth
            />

            <TextInputField
              {...papirflyStoreIdController.field}
              error={papirflyStoreIdController.fieldState.error?.message}
              label={t('location.detail.ecommerce.papirflyStoreId')}
              fullWidth
            />

            <CheckboxWithLabel
              {...hasInventoryManagementController.field}
              label={t('location.detail.ecommerce.hasInventoryManagement')}
              size={'medium'}
            />

            <SaveOrCancelEdit save={onSubmit} cancel={onCancel} align="right" loading={saving} />
          </Stack>
        </FormProvider>
      ) : (
        <LabelValueTable
          items={[
            {
              label: t('location.detail.ecommerce.assortment'),
              value: ecommerce?.ecomAssortment?.title,
            },
            {
              label: t('location.detail.ecommerce.papirflyStoreId'),
              value: ecommerce?.papirflyStoreId,
            },
            {
              label: t('location.detail.ecommerce.hasInventoryManagement'),
              value: ecommerce?.hasInventoryManagement ? t('common.yes') : t('common.no'),
            },
            ...(showOmniumData
              ? [
                  {
                    label: t('location.detail.omnium.connectToOmnium'),
                    value: publiclyAvailable ? t('common.yes') : t('common.no'),
                  },
                ]
              : []),
          ]}
        />
      )}
    </EditCard>
  );
};

export default EcommerceCard;
