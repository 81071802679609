import { ByggplanleggerIdSchema, useByggplanleggerIdSchema } from '@dap-admin/schemas';
import { LocationByggplannleggerSchema } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { EditCard, LabelValue, SaveOrCancelEdit } from '@dap-common/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, useTheme } from '@mui/material';
import { TextInputField } from '@shared/components';
import { useState } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
interface Props {
  hasAdminRights: boolean;
  byggplanleggerId: string;
  save: (updatedId: LocationByggplannleggerSchema) => void;
  saving: boolean;
}

const ByggplanleggerCard = ({ byggplanleggerId, save, saving, hasAdminRights }: Props) => {
  const t = useAppTFunction();
  const [isEditing, setIsEditing] = useState(false);
  const { palette } = useTheme();

  const form = useForm<ByggplanleggerIdSchema>({
    defaultValues: useByggplanleggerIdSchema(byggplanleggerId).getDefault(),
    resolver: yupResolver(useByggplanleggerIdSchema(byggplanleggerId)),
  });
  const { handleSubmit, control, reset } = form;
  const byggplanleggerIdController = useController({ name: 'byggplanleggerId', control });

  const onSubmit = handleSubmit((byggplanleggerId) => {
    save(byggplanleggerId);
    setIsEditing(false);
  });

  const onCancel = () => {
    setIsEditing(false);
    reset();
  };

  return (
    <EditCard
      header={t('location.detail.byggplanleggerId')}
      isEditing={isEditing}
      edit={() => setIsEditing(true)}
      cancel={onCancel}
      isEditable={hasAdminRights}
    >
      {isEditing ? (
        <FormProvider {...form}>
          <Stack spacing={2} pt={1}>
            <TextInputField
              {...byggplanleggerIdController.field}
              error={byggplanleggerIdController.fieldState.error?.message}
              label={t('location.detail.byggplanleggerId')}
              fullWidth
            />
            <SaveOrCancelEdit save={onSubmit} loading={saving} cancel={onCancel} align="right" />
          </Stack>
        </FormProvider>
      ) : (
        <LabelValue
          label={t('location.detail.byggplanleggerId')}
          value={byggplanleggerId || '-'}
          labelTypographyProps={{ variant: 'body1' }}
          valueTypographyProps={{
            variant: 'body1',
            color: palette.text.secondary,
          }}
        />
      )}
    </EditCard>
  );
};

export default ByggplanleggerCard;
