import { useTranslation } from 'react-i18next';
import { TOptions } from 'i18next';
import { MessagePaths } from '../utils/messageTypeUtils';
import { capitalizeFirstLetter } from '@shared/utils';

type Format = 'capitalize' | 'uppercase' | 'lowercase';
type FormatFn = (str: string) => string;

const formatMap: Record<Format, FormatFn> = {
  capitalize: capitalizeFirstLetter,
  uppercase: (str) => str.toUpperCase(),
  lowercase: (str) => str.toLowerCase(),
};

export const useMessagesTranslation = <MessagesT extends Record<string, any>>() => {
  const translation = useTranslation();

  const appT = (key: MessagePaths<MessagesT>, options?: TOptions, format?: Format) => {
    const [keyNs, ...rest] = key.split('.');

    const text = translation.t(rest.join('.'), {
      ns: keyNs,
      ...options,
    });

    return format ? formatMap[format](text) : text;
  };

  return {
    ...translation,
    t: appT,
  };
};