import { clientIdSchema, DapClientIdSchema } from '@dap-admin/types';
import { useAppTFunction } from '@dap-common/i18n';
import { LabelValue, UniversalFormProvider } from '@dap-common/ui';
import { useState } from 'react';
import BrandConfigCard from '../BrandConfigCard/BrandConfigCard';
import EditDapClientId from './EditDapClientId';

interface Props {
  clientId: string;
  handleSave: (input: DapClientIdSchema) => void;
}

function DapClientId({ clientId, handleSave }: Props) {
  const t = useAppTFunction();
  const [isEditing, setIsEditing] = useState(false);

  const updateClientId = (input: DapClientIdSchema) => {
    if (input.employeePortalClientId !== clientId) {
      handleSave(input);
    }
    setIsEditing(false);
  };

  return (
    <BrandConfigCard
      header={t('brandDetailsPage.dapAuth0ClientId.title')}
      isEditing={isEditing}
      handleEdit={() => setIsEditing(true)}
      handleCancelEdit={() => setIsEditing(false)}
      hasAdminRights
    >
      {isEditing ? (
        <UniversalFormProvider
          schema={clientIdSchema}
          defaultValues={{ employeePortalClientId: clientId || '' }}
        >
          <EditDapClientId cancelEdit={() => setIsEditing(false)} handleSave={updateClientId} />
        </UniversalFormProvider>
      ) : (
        <LabelValue
          label={t('brandDetailsPage.dapAuth0ClientId.form.title')}
          value={clientId ?? t('brandDetailsPage.dapAuth0ClientId.empty')}
          labelTypographyProps={{ variant: 'body1' }}
          valueTypographyProps={{
            variant: 'body1',
            color: 'text.secondary',
          }}
        />
      )}
    </BrandConfigCard>
  );
}

export default DapClientId;
