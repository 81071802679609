export interface CompleteApp {
  key: string;
  name: string;
  url: string;
  logoUrl: string;
  default: boolean;
}

export interface AppConfigV2 {
  name: string | null;
  url: string | null;
}

export interface AppBasic {
  uuid: string | null;
  name: string;
  key: string;
  default: boolean;
}

export interface AppDetailed {
  uuid: string | null;
  name: string;
  key: string;
  updatedAt: string | null;
  updatedBy: string | null;
  default: boolean;
  config: AppConfigV2;
  url: string | null;
  ssoEnabled: boolean;
  integrationEnabled: boolean;
}

export interface PropertyOption {
  id: number | null;
  displayName: string | null;
  options: Array<string>;
}

export interface Property {
  key: string;
  value: string;
  enabled: boolean;
  keyGroup: string;
  valueType: string;
  options: PropertyOption | null;
  optionsId: number | null;
  isLocked: boolean;
}

export enum PropertyValueTypes {
  string = 'string',
  number = 'number',
  boolean = 'boolean',
  date = 'date',
  enum = 'enum',
  multipleChoice = 'multiple-choice',
}

export type CreateProperty = Omit<Property, 'options' | 'optionsId'> & {
  options: Partial<PropertyOption> | null;
};

export type EditProperty = Omit<Property, 'options' | 'optionsId' | 'key' | 'valueType'>;

/**
 * @deprecated replaced by @RegionV2
 */
export interface Region {
  key: string;
  id: number;
  name: string;
  desc: string;
  postFix: string;
}

export interface RegionV2 {
  uuid: string;
  name: string;
  key: string;
}

export enum AvailableLocationFields {
  PinMeTo = 'pinmeto',
  Erp = 'erp',
  Gln = 'gln',
  PubliclyAvailable = 'publiclyAvailable',
  Ecommerce = 'ecommerce',
  MgPris = 'mgPris',
  ShowDataownerAndLocationUnited = 'showDataownerAndLocationUnited',
}

export interface EcomAssortment {
  key: string;
  title: string;
}

export enum InformationModelType {
  Brand = 'brand',
  Dataowner = 'dataowner',
  Location = 'location',
  Super = 'super',
  Employee = 'employee',
}

export type LocationField =
  | 'gln'
  | 'publiclyAvailable'
  | 'ecommerce'
  | 'pinmeto'
  | 'mgPris'
  | 'erp';

export interface BrandLocationFieldConfig {
  fieldName: LocationField;
  show: boolean;
  id: number;
}

export interface Role {
  '@type': InformationModelType;
  key: string; //entityKey to match a brand, dataowner or location
  role: string;
  title: string;
  genericTitle?: string;
  selfAssign?: boolean;
  admin?: boolean;
}

export interface RoleDetailed {
  key: string;
  title: string;
  isAdmin: boolean;
  selected: boolean;
}

export interface OrgUnitRole {
  role: RoleDetailed;
  orgUnitKey: string;
  type: string;
}

export enum ErpType {
  NEXSTEP = 'NEXSTEP',
  VISMA_BUSINESS = 'VISMA_BUSINESS',
}

export interface ErpInfo {
  brandId: string;
  erpType: ErpType;
  fileGroup: string;
  warehouseId: string;
}

export interface LocationProperty {
  key: string;
  title: string;
  description: string | null;
}

export interface KeyValue {
  id?: number;
  key: string;
  value: string;
}

export enum Operation {
  useExisting = 'useExisting',
  search = 'search',
  create = 'create',
}
